import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="24"
      viewBox="0 0 39 24"
    >
      <path
        fill="#D7DAFF"
        fillRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M2.84 0C1.299 0 .043 1.27.043 2.826V21.18c0 1.555 1.256 2.82 2.799 2.82h32.397c1.543 0 2.804-1.27 2.804-2.82V2.826C38.042 1.271 36.78 0 35.238 0H2.84zm31.716 8.215V4.48a.429.429 0 00-.43-.434h-8.29a.435.435 0 00-.43.434v3.736c0 .238.195.434.43.434h8.29c.23 0 .43-.191.43-.434zm-6.11 11.326a.437.437 0 01-.436-.434v-.868c0-.238.195-.434.436-.434h5.674c.236 0 .43.196.43.434v.868a.435.435 0 01-.43.434h-5.674zm-7.92 0a.435.435 0 01-.43-.434v-.863a.43.43 0 01.43-.434h5.68c.235 0 .43.196.43.434v.863a.435.435 0 01-.43.434h-5.68zm-7.915 0a.435.435 0 01-.43-.434v-.863a.43.43 0 01.43-.434h5.68c.236 0 .43.196.43.434v.863a.435.435 0 01-.43.434h-5.68zm-7.915 0a.435.435 0 01-.43-.434v-.863c0-.243.195-.434.43-.434h5.68c.236 0 .43.196.43.434v.863a.435.435 0 01-.43.434h-5.68z"
      />
    </svg>
  )
}

export default Icon
