import { getInitialDateByType, getWeekNumber } from 'libs/calendar'
import {
  SET_YEAR_AND_STEP,
  SET_MEMBER_ID,
  UPDATE_TIMESHEET_PROJECTS,
  SET_TIMESHEET_PROJECTS,
  SET_TIMESHEET_ID,
  SET_TIMESHEET_STATUS,
  UPDATE_PROJECTS_WITH_NEW,
  SET_ALL_TYPES_PROJECTS,
  ADD_TIME_OFF_TO_PROJECTS,
  UPDATE_TIMESHEET_PROJECT_ITEM,
  SET_SUBMIT_TYPE,
  SET_UNHANDLED_EDIT,
  SET_SHOW_MODAL,
  ADD_DELETED_ROW_ID
} from '../actions/timeEntry'

// TimeSheet status:
//   1 => Draft
//   2 => Pending for approval
//   3 => Approved
// TimeSheetRows status:
//   1 => Draft
//   2 => Pending for approval
//   3 => Approved
//   4 => Pending edit request
//   5 => Approved and Invoiced

const initialDate = getInitialDateByType('weekly')

export const initialState = {
  member_id: null,
  table_cols: [],
  week_days: 7,
  step_mode: 'weekly',
  time_sheet_id: null,
  year: new Date().getFullYear().toString(),
  step_of_year: getWeekNumber(initialDate).toString(),
  submit_type: 'post',
  send_to_approval: 1,
  confirm: 1,
  start_week: initialDate[0],
  end_week: initialDate[1],
  timesheet_data: null,
  timesheet_projects: [],
  initial_timesheet_projects: [],
  all_assigned_projects: [],
  weekly_planning: [],
  last_week_projects: [],
  isFetchedTabsData: false,
  deletedIds: [],
  unhandledEdit: false,
  showModal: false,
  // eslint-disable-next-line max-len
  timeSheetStatus: '' // types = Send for approval || Pending for approval || Partially approved || Approved || Approved and invoiced
}

function timeEntryReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_YEAR_AND_STEP:
      return {
        ...state,
        year: action.payload.year,
        step_of_year: action.payload.step_of_year,
        start_week: action.payload.start_week,
        end_week: action.payload.end_week,
        table_cols: action.payload.table_cols,
        deletedIds: action.payload.deletedIds
      }

    case SET_MEMBER_ID:
      return { ...state, member_id: action.payload }

    case UPDATE_TIMESHEET_PROJECTS:
      return {
        ...state,
        timesheet_projects: action.payload.projects,
        timeSheetStatus: action.payload.timeSheetStatus
      }

    case SET_TIMESHEET_PROJECTS:
      return {
        ...state,
        timesheet_projects: action.payload.projects,
        initial_timesheet_projects: action.payload.initialProjects,
        timesheet_data: action.payload.other
          ? action.payload.other
          : state.timesheet_data,
        submit_type: action.payload.submit_type,
        timeSheetStatus: action.payload.timeSheetStatus,
        time_sheet_id: action.payload.id
      }

    case SET_TIMESHEET_ID:
      return { ...state, time_sheet_id: action.payload }

    case SET_TIMESHEET_STATUS:
      return { ...state, timeSheetStatus: action.payload }

    case UPDATE_PROJECTS_WITH_NEW:
      return {
        ...state,
        timesheet_projects: [...state.timesheet_projects, ...action.payload.projects],
        timeSheetStatus: action.payload.timeSheetStatus
      }

    case SET_ALL_TYPES_PROJECTS:
      return {
        ...state,
        all_assigned_projects: action.payload.all_assigned_projects,
        weekly_planning: action.payload.weekly_planning,
        last_week_projects: action.payload.last_week_projects,
        isFetchedTabsData: action.payload.isFetchedTabsData
      }

    case ADD_TIME_OFF_TO_PROJECTS:
      return {
        ...state,
        timesheet_projects: [...state.timesheet_projects, action.payload]
      }

    case UPDATE_TIMESHEET_PROJECT_ITEM:
      return {
        ...state,
        timesheet_projects: state.timesheet_projects.map(tp => {
          const { uuid } = action.payload.project
          if (tp.uuid === uuid) return action.payload.project
          return tp
        }),
        timeSheetStatus: action.payload.timeSheetStatus
      }

    case SET_SUBMIT_TYPE:
      return {
        ...state,
        timeSheetStatus: action.payload
      }

    case ADD_DELETED_ROW_ID:
      return {
        ...state,
        deletedIds: !state.deletedIds.includes(action.payload)
          ? [...state.deletedIds, action.payload]
          : state.deletedIds
      }

    case SET_UNHANDLED_EDIT:
      return {
        ...state,
        unhandledEdit: action.payload
      }

    case SET_SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload
      }

    default:
      return state
  }
}

export default timeEntryReducer
