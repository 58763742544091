import { SET_COMPANY_INFO, SET_LOADING } from '../actions/company'

const initialState = {
  loading: {
    getCompanyInfo: false,
    updateCompanyInfo: false
  },
  info: {}
}

function companyReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  const { payload, type } = action

  switch (type) {
    case SET_COMPANY_INFO:
      return { ...state, info: { ...state.info, ...payload } }

    case SET_LOADING:
      return { ...state, loading: { ...state.loading, [payload.key]: payload.value } }

    default:
      return state
  }
}

export default companyReducer
