// eslint-disable-next-line no-unused-vars
import React from 'react'
import { useTranslation } from 'react-i18next'

const useTranslate = category => {
  const { t } = useTranslation()
  const getTranslationItem = item => {
    const result = t(`${category}.${item}`)
    return result
  }
  return getTranslationItem
}

export default useTranslate
