import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="tag_filled_aaa"
          /* eslint-disable-next-line max-len */
          d="M11.008 1a.796.796 0 01.595.24l10.439 10.425a3.26 3.26 0 010 4.616l-5.753 5.76a3.257 3.257 0 01-4.616 0L1.24 11.604a.818.818 0 01-.239-.595l.18-7.7a2.17 2.17 0 012.128-2.128zM6.614 6.61a2.001 2.001 0 000 2.828c.78.781 2.044.782 2.824 0a1.986 1.986 0 00.001-2.828 1.997 1.997 0 00-2.825 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fillRule="nonzero" d="M0 0h24v24H0z" />
        <mask id="tag_filled_bbb" fill="#fff">
          <use xlinkHref="#tag_filled_aaa" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#tag_filled_aaa" />
        <g fill="#6B75FF" mask="url(#tag_filled_bbb)">
          <path d="M24 0v24H0V0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
