import isEmpty from 'lodash.isempty'

import useStore from './useStore'

/**
 * @returns {boolean} Whether user info is stored or not
 */

const useAuth = () => {
  const { state } = useStore('AuthContext')

  return !isEmpty(state.info)
}

export default useAuth
