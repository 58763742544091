import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import cx from 'clsx'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { __RouterContext } from 'react-router'
import ROUTES, { EXTERNAL_LINKS, ROUTES_HAVE_SIDEBAR } from 'routes/routes'
import storage from 'libs/storage'
import { AUTH_TOKEN } from 'constants/app'
import { LogoutIcon } from 'components/Svg'
import { useDispatch, useSelector } from 'react-redux'
import { setShowModalTimeEntry, setunhandledEdit } from 'store/actions/timeEntry'
import { setRoute } from 'store/actions/app'
import checkPermission from 'libs/permission'
import { useStore } from 'hooks'
import useGa from 'hooks/useGa'
import useTranslate from 'hooks/useTranslate'
import MenuLogo from '../MenuLogo/MenuLogo'

import SIDEBAR_ROUTES from './routes'
import s from './SideBar.module.scss'

const minHeightOfSidebar = 824

function SideBar() {
  const { state: globalState } = useStore('GlobalContext')
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)
  const unhandledEditTimeEntry = useSelector(state => state.timeEntry.unhandledEdit)
  const dispatch = useDispatch()
  const { history, location } = useContext(__RouterContext)
  const [isOpen, setIsOpen] = useState(true)
  const [isMiniSize, setIsMiniSize] = useState(false)
  const ga = useGa('SideBar')
  const t = useTranslate('general.sideBar')
  useEffect(() => {
    const isRouteWithSidebar = ROUTES_HAVE_SIDEBAR.includes(location.pathname)

    if (isRouteWithSidebar && !isOpen) setIsOpen(true)

    if (!isRouteWithSidebar && isOpen) setIsOpen(false)
  }, [location.pathname]) // eslint-disable-line

  useLayoutEffect(() => {
    dispatch(setunhandledEdit(false))
    function updateSize() {
      if (window.innerHeight > minHeightOfSidebar && isMiniSize) setIsMiniSize(false)
      if (window.innerHeight < minHeightOfSidebar && !isMiniSize) setIsMiniSize(true)
    }
    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [isMiniSize])

  const handleClick = type => () => {
    ga('Change Page', type)
    if (unhandledEditTimeEntry) {
      dispatch(setShowModalTimeEntry(true))
      dispatch(setRoute(type))
    } else {
      history.push(type)
    }
  }

  const handleLogout = () => {
    ga('Logout')
    storage.remove(AUTH_TOKEN)
    storage.remove('root')
    localStorage.clear()
    setTimeout(() => history.push(ROUTES.SIGNIN), 300)
  }

  const activeTab = path => path === location.pathname

  const toggleSidebarExpasion = boolValue => {
    setIsSidebarExpanded(boolValue)
  }

  return (
    <div
      className={cx(s.container, { [s.open]: isOpen, [s.mini]: isMiniSize })}
      id="sidebar-container"
      onMouseEnter={() => toggleSidebarExpasion(true)}
      onFocus={() => toggleSidebarExpasion(true)}
      onMouseLeave={() => toggleSidebarExpasion(false)}
      onBlur={() => toggleSidebarExpasion(false)}
    >
      <a href={EXTERNAL_LINKS.WEBSITE} target="_blank" rel="noopener noreferrer">
        <div className={s.logoContainer}>
          <div className={s.logo}>
            <MenuLogo expand={isSidebarExpanded} />
          </div>
        </div>
      </a>

      <List className={s.appItems}>
        {SIDEBAR_ROUTES.app
          .filter(item =>
            checkPermission(item.permission, globalState.permissions, {
              projectId: 'ALL',
              teamId: 'ALL'
            })
          )
          .map((item, index) => (
            <ListItem
              button
              key={item.title}
              classes={{
                root: cx(s.listItem, {
                  [s.active]: activeTab(item.path),
                  [s.disabled]: item.disabled
                })
              }}
              onClick={handleClick(item.path)}
              data-testid="menu-item"
            >
              <ListItemIcon>
                <div className={s.icon}>
                  <img src={item.icon} alt={item.title} />
                </div>
              </ListItemIcon>
              <ListItemText classes={{ root: s.listItemText }} primary={t(index)} />
            </ListItem>
          ))}
      </List>

      <List className={s.userItems}>
        {SIDEBAR_ROUTES.user
          .filter(item => checkPermission(item.permission, globalState.permissions))
          .map(item => (
            <ListItem
              button
              key={item.title}
              classes={{ root: cx(s.listItem, { [s.active]: activeTab(item.path) }) }}
              onClick={handleClick(item.path)}
              disabled={item.disabled}
              data-testid="menu-item"
            >
              <ListItemIcon>
                <div className={s.icon}>
                  <img src={item.icon} alt={item.title} />
                </div>
              </ListItemIcon>
              <ListItemText
                classes={{ root: s.listItemText }}
                primary={t(item.title)}
              />
            </ListItem>
          ))}
        <ListItem
          button
          classes={{ root: s.listItem }}
          onClick={handleLogout}
          data-testid="menu-item"
        >
          <ListItemIcon>
            <div className={s.icon}>
              <LogoutIcon />
            </div>
          </ListItemIcon>
          <ListItemText classes={{ root: s.listItemText }} primary={t(6)} />
        </ListItem>
      </List>
    </div>
  )
}

export default SideBar
