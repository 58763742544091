import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="prefix_reply__a"
          /* eslint-disable-next-line max-len */
          d="M13 3c0-.906 1.108-1.345 1.728-.685l8 8.5c.361.383.363.98.004 1.366l-8 8.6c-.619.665-1.732.227-1.732-.681v-4.078c-4.012.18-7.458 1.45-10.37 3.814A1 1 0 011 19.06C1 12.605 6.107 7.486 13 7.033V3zm2 5a1 1 0 01-1 1c-5.5 0-9.765 3.36-10.773 7.95C6.343 14.983 9.942 14 14 14a1 1 0 011 1v2.557l5.63-6.053L15 5.522V8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 24 0)">
        <path fillRule="nonzero" d="M0 0h24v24H0z" />
        <mask id="prefix_reply__b" fill="#fff">
          <use xlinkHref="#prefix_reply__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#prefix_reply__a" />
        <g fill="#6B75FF" mask="url(#prefix_reply__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
