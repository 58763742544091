/* eslint-disable max-len */
import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="32"
      viewBox="0 0 29 32"
    >
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M20 22h-7.333c-1.103 0-2-.897-2-2v-8c0-1.103.897-2 2-2H20a.667.667 0 00.667-.667V2c0-1.103-.898-2-2-2h-16c-1.103 0-2 .897-2 2v28c0 1.103.897 2 2 2h16c1.102 0 2-.897 2-2v-7.333A.667.667 0 0020 22zm8.439-6.501l-5.334-4.667a.672.672 0 00-.714-.105.664.664 0 00-.391.606v2h-7.333A.667.667 0 0014 14v4c0 .368.299.667.667.667H22v2a.667.667 0 001.105.501l5.334-4.667a.664.664 0 000-1.002z"
      />
    </svg>
  )
}

export default Icon
