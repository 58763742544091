import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="Check2Disable_prefix__a"
          /* eslint-disable-next-line max-len */
          d="M16.707 6.293a1 1 0 010 1.414l-10 10a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L6 15.586l9.293-9.293a1 1 0 011.414 0zm6 0a1 1 0 010 1.414l-10 10a1 1 0 01-1.414-1.414l10-10a1 1 0 011.414 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fillRule="nonzero" d="M0 0h24v24H0z" />
        <mask id="Check2Disable_prefix__b" fill="#fff">
          <use xlinkHref="#Check2Disable_prefix__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#Check2Disable_prefix__a" />
        <g fill="#E4E9F2" mask="url(#Check2Disable_prefix__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
