import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import userReducer from './user'
import appReducer from './app'
import planningReducer from './planning'
import invoicingReducer from './invoicing'
import invoicingNewReducer from './invoicingNew'
import timeEntryReducer from './timeEntry'
import dashboardReducer from './dashboard'
import companyReducer from './company'
import settingsReducer from './settings'
import reportsReducer from './reports'
import timeApprovalReducer from './timeApproval'
import subscriptionReducer from './subscription'
import onboardingReducer from './onboarding'

const rootReducer = combineReducers({
  form: formReducer,
  user: userReducer,
  app: appReducer,
  planning: planningReducer,
  invoicing: invoicingReducer,
  invoicingNew: invoicingNewReducer,
  timeEntry: timeEntryReducer,
  dashboard: dashboardReducer,
  company: companyReducer,
  settings: settingsReducer,
  reports: reportsReducer,
  timeApproval: timeApprovalReducer,
  subscription: subscriptionReducer,
  onboarding: onboardingReducer
})

export default rootReducer
