import { SET_INSIGHTS, SET_LOADING, SET_PLANS } from '../actions/dashboard'

export const initialState = {
  loading: {
    insights: false,
    plans: false,
    addToTimesheet: false
  },
  insights: [],
  plans: {}
}

function dashboardReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  const { payload, type } = action

  switch (type) {
    case SET_INSIGHTS:
      return { ...state, insights: payload }

    case SET_PLANS:
      return { ...state, plans: payload }

    case SET_LOADING:
      return { ...state, loading: { ...state.loading, [payload.key]: payload.value } }

    default:
      return state
  }
}

export default dashboardReducer
