import ReactGA from 'react-ga'

const ga = {
  /**
   * pageview:
   * Basic GA pageview tracking
   * @param  {String} path - the current page page e.g. '/about'
   * @param {String} title - (optional) the page title e. g. 'My Website'
   */
  pageview: (path, title) => {
    ReactGA.pageview(path, [], title)
  },

  /**
   * modalview:
   * a proxy to basic GA pageview tracking to consistently track
   * modal views that are an equivalent UX to a traditional pageview
   * @param  {String} modalName e.g. 'add-or-edit-club'
   */
  modalview: modalName => {
    ReactGA.modalview(modalName)
  },

  /**
   * event:
   * GA event tracking
   * @param args.category {String} required
   * @param args.action {String} required
   * @param args.label {String} optional
   * @param args.value {Int} optional
   */
  event: ({ category, action, label, value }) => {
    ReactGA.event({
      category,
      action,
      label,
      value
    })
  }
}

export default ga
