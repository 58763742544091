import React from 'react'
import PropTypes from 'prop-types'

import ProgressBar from 'components/ProgressBar'
import { Logo } from 'components/Svg'
import { getInRange } from 'libs/utils'

import s from './InitialLoading.module.scss'

const INITIAL_REQUESTS_COUNT = 4

const InitialLoading = ({ progress }) => {
  return (
    <div className={s.container}>
      <Logo />
      <ProgressBar
        value={getInRange(progress * (100 / INITIAL_REQUESTS_COUNT), {
          min: 0,
          max: 100
        })}
        variant="determinate"
        className={s.loadingContainer}
        classes={{
          colorPrimary: s.loadingColorPrimary,
          barColorPrimary: s.loadingBarColorPrimary
        }}
      />
    </div>
  )
}

InitialLoading.propTypes = {
  progress: PropTypes.number.isRequired
}

export default InitialLoading
