import {
  DELETE_MEMBER_LIST,
  ADD_MEMBER_LIST,
  LOADING_MEMBER_LIST,
  ADD_CLIENT,
  LOADING_CLIENT,
  EDITMODE_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  UPDATE_PROJECT,
  LOADING_PROJECT,
  EDITMODE_PROJECT,
  ADD_PROJECT,
  DELETE_PROJECT,
  UPDATE_NAME,
  UPDATE_CAPACITY,
  UPDATE_DAY,
  CLEAR_STATE,
  SUBMIT_MEMBER,
  SUBMIT_CLIENT,
  SUBMIT_PROJECT,
  SET_GENERATED_DATA,
  TOGGLE_EDIT_CLIENT,
  AFTER_SUBMIT,
  UPDATE_HANDLEINPUT
} from '../actions/onboarding'

const initialState = {
  memberList: [],
  clientList: [],
  projectList: [],
  costumerInfo: { name: '', capacity: 40, day: 4 },
  unhandledInput: false,
  submitMember: false,
  submitClient: false,
  submitProject: false,
  isAfterSubmit: false,
  generatedData: {},
  clientId: null
}

function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    // members

    case DELETE_MEMBER_LIST:
      return {
        ...state,
        memberList: state.memberList.filter(member => member.id !== action.payload)
      }
    case ADD_MEMBER_LIST:
      return {
        ...state,
        memberList: [...state.memberList, action.payload]
      }
    case SUBMIT_MEMBER:
      return {
        ...state,
        submitMember: action.payload
      }
    case LOADING_MEMBER_LIST:
      return {
        ...state,
        memberList: state.memberList.map(member =>
          member.id === action.payload
            ? { ...member, loading: !member.loading }
            : member
        )
      }

    // Clients

    case DELETE_CLIENT:
      return {
        ...state,
        clientList: state.clientList.filter(client => client.id !== action.payload)
      }
    case ADD_CLIENT:
      return {
        ...state,
        clientList: [...state.clientList, action.payload]
      }
    case SUBMIT_CLIENT:
      return {
        ...state,
        submitClient: action.payload
      }
    case UPDATE_CLIENT:
      return {
        ...state,
        clientList: state.clientList.map(client =>
          client.id === action.payload.id
            ? { ...client, name: action.payload.name }
            : client
        )
      }
    case LOADING_CLIENT:
      return {
        ...state,
        clientList: state.clientList.map(client =>
          client.id === action.payload
            ? { ...client, loading: !client.loading }
            : client
        )
      }
    case EDITMODE_CLIENT:
      return {
        ...state,
        clientList: state.clientList.map(client =>
          client.id === action.payload
            ? { ...client, editMode: !client.editMode }
            : client
        )
      }
    case TOGGLE_EDIT_CLIENT:
      return {
        ...state,
        clientId: action.payload
      }

    // projects

    case DELETE_PROJECT:
      return {
        ...state,
        projectList: state.projectList.filter(client => client.id !== action.payload)
      }
    case ADD_PROJECT:
      return {
        ...state,
        projectList: [...state.projectList, action.payload]
      }
    case SUBMIT_PROJECT:
      return {
        ...state,
        submitProject: action.payload
      }
    case UPDATE_PROJECT:
      return {
        ...state,
        projectList: state.projectList.map(client =>
          client.id === action.payload.id
            ? { ...client, name: action.payload.name }
            : client
        )
      }
    case LOADING_PROJECT:
      return {
        ...state,
        projectList: state.projectList.map(client =>
          client.id === action.payload
            ? { ...client, loading: !client.loading }
            : client
        )
      }
    case EDITMODE_PROJECT:
      return {
        ...state,
        projectList: state.projectList.map(client =>
          client.id === action.payload
            ? { ...client, editMode: !client.editMode }
            : client
        )
      }

    // costumer Info

    case UPDATE_NAME:
      return {
        ...state,
        costumerInfo: { ...state.costumerInfo, name: action.payload }
      }
    case UPDATE_CAPACITY:
      return {
        ...state,
        costumerInfo: { ...state.costumerInfo, capacity: action.payload }
      }
    case UPDATE_DAY:
      return {
        ...state,
        costumerInfo: { ...state.costumerInfo, day: action.payload }
      }
    // Inputs
    case UPDATE_HANDLEINPUT:
      return {
        ...state,
        unhandledInput: action.payload
      }
    // Clear State
    case CLEAR_STATE:
      return {
        memberList: [],
        clientList: [],
        projectList: [],
        costumerInfo: { name: ' ', capacity: 40, day: 4 },
        unhandledInput: false
      }
    // set generated data
    case SET_GENERATED_DATA:
      return {
        ...state,
        generatedData: action.payload
      }
    case AFTER_SUBMIT:
      return {
        ...state,
        isAfterSubmit: action.payload
      }

    default:
      return state
  }
}

export default onboardingReducer
