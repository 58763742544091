import { spring } from 'react-router-transition'
import cx from 'clsx'

import storage from 'libs/storage'
import { AUTH_TOKEN } from 'constants/app'

export function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`
  }
}

export function bounce(val) {
  return spring(val, {
    stiffness: 220,
    damping: 22
  })
}

export const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8)
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1)
  }
}

export const snackbarClasses = {
  variantSuccess: cx('snackbar', 'snackbar-success'),
  variantError: cx('snackbar', 'snackbar-error')
}

export function isLoggedIn() {
  try {
    const token = storage.load(AUTH_TOKEN)

    return !!token
  } catch (e) {
    return false
  }
}
