// Types
export const SET_TOKEN = 'SET_TOKEN'
export const ADD_TOKEN = 'ADD_TOKEN'
export const SET_USER_INFO = 'SET_USER_INFO'
export const LOG_OUT = 'LOG_OUT'

// Creators
export const setToken = token => ({
  type: SET_TOKEN,
  payload: token
})

export const setUserInfo = info => ({
  type: SET_USER_INFO,
  payload: info
})

export const logout = () => ({
  type: LOG_OUT
})
