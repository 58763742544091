// eslint-disable-next-line no-unused-vars
import React from 'react'
import ga from 'react-ga'

const useGa = (category = 'Event Category') => {
  const trackEvent = (action = 'action', label = '- -') => {
    ga.event({ category, action, label })
  }
  return trackEvent
}

export default useGa
