import APIConstants from './constants'
import APIFetcher, { abortFetcher } from './utils/fetcher'

const APIs = {
  // Custom method for request abort
  ABORT: abortId => {
    abortFetcher(abortId)
  }
}

APIConstants.forEach(constant => {
  constant.endpoints.forEach(endpoint => {
    APIs[endpoint.key] = (params, ignoreErrorCodes = []) =>
      APIFetcher(
        constant.url,
        endpoint.method,
        endpoint.url,
        endpoint.mock,
        params,
        'json',
        ignoreErrorCodes
      )
  })
})

export default APIs
