import React from 'react'

function Icon() {
  return (
    <svg width="250px" height="48px" viewBox="0 0 217 48">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fontFamily="Heebo-Black, Heebo"
        fontSize="62"
        fontWeight="700"
        transform="translate(-96.000000, -169.000000)"
      >
        <text>
          <tspan x="93" y="216" fill="#8AE560">
            Bis
          </tspan>
          <tspan x="182.246094" y="216" fill="#FFFFFF">
            Flow
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default Icon
