import moment from 'moment'

export const THIS_YEAR = +new Date().getFullYear()
export const THIS_MONTH = +new Date().getMonth() + 1

export const WEEK_DAYS = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun'
}

// Starting day had to be sunday, so I created another variable
export const STANDARD_FULL_WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const WEEK_DAY_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const CALENDAR_MONTHS = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec'
}

export const CALENDAR_MONTHS_ARRAY = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const CALENDAR_WEEKS = 6

export const zeroPad = (value, length) => `${value}`.padStart(length, '0')

export const fillTime = time => {
  if (!time) return '00:00'

  const splitted = time.split(':')

  if (splitted[0].length !== 2) {
    splitted[0] = `0${splitted[0]}`
  }

  if (splitted[1].length !== 2) {
    splitted[1] = `0${splitted[1]}`
  }
  return splitted.join(':')
}

export const isDate = date => {
  const objectIsDate = Object.prototype.toString.call(date) === '[object Date]'
  const isValidDate = date && !Number.isNaN(date.valueOf())
  return objectIsDate && isValidDate
}

export const getDateISO = (date = new Date()) => {
  if (!isDate(date)) return null

  return [
    date.getFullYear(),
    zeroPad(+date.getMonth() + 1, 2),
    zeroPad(+date.getDate(), 2)
  ].join('-')
}

export const getCurrentWeek = date => {
  const week = []

  const day = new Date(date.getTime())

  day.setDate(date.getDate() + ((7 - date.getDay()) % 7))

  week[1] = getDateISO(new Date(day))

  day.setDate(day.getDate() - 6)

  week[0] = getDateISO(new Date(day))

  return week
}

export const getNumberOfWeek = date => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000
  return Math.floor((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
}

export const getCurrentMonth = () => {
  const currentDate = new Date()
  const currentMonthIndex = currentDate.getMonth()
  const monthsArr = Object.keys(CALENDAR_MONTHS)

  return CALENDAR_MONTHS[monthsArr[currentMonthIndex]]
}

export const getCurrentQuarter = () => {
  const today = new Date()
  const quarter = Math.floor((today.getMonth() + 3) / 3)

  return quarter - 1
}

export const getInitialDateByType = (type, dateISO = null) => {
  const currentDate = dateISO ? new Date(dateISO) : new Date()

  switch (type) {
    case 'weekly':
      return getCurrentWeek(currentDate)
    case 'monthly':
      return [getCurrentMonth(), currentDate.getFullYear()]
    case 'quarterly':
      return [getCurrentQuarter(), currentDate.getFullYear()]
    default:
      return getCurrentWeek(currentDate)
  }
}

export const getWeekNumber = dates => {
  const fromDate = dates[0]
  const splittedFrom = fromDate.split('-')
  const yearAndMonth = `${splittedFrom[0]}-${splittedFrom[1]}`
  const middleDay = Number(splittedFrom[2])
  const date = new Date(`${yearAndMonth}-${middleDay}`)
  const weekNumber = getNumberOfWeek(date)
  return weekNumber
}

export const getPrevDay = date => {
  const dayDate = new Date(date)
  const day = new Date(dayDate.getTime())

  day.setDate(dayDate.getDate() - 1)

  return getDateISO(date)
}

export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [4, 6, 9, 11]
  const leapYear = year % 4 === 0
  let result

  if (month === 2) {
    result = leapYear ? 29 : 28
  } else {
    result = months30.includes(month) ? 30 : 31
  }

  return result
}

export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
  return +new Date(`${year}-${zeroPad(month, 2)}-01`).getDay() + 1
}

export const isSameMonth = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false

  const basedateMonth = +basedate.getMonth() + 1
  const basedateYear = basedate.getFullYear()

  const dateMonth = +date.getMonth() + 1
  const dateYear = date.getFullYear()

  return +basedateMonth === +dateMonth && +basedateYear === +dateYear
}

export const isSameDay = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false

  const basedateDate = basedate.getDate()
  const basedateMonth = +basedate.getMonth() + 1
  const basedateYear = basedate.getFullYear()

  const dateDate = date.getDate()
  const dateMonth = +date.getMonth() + 1
  const dateYear = date.getFullYear()

  return (
    +basedateDate === +dateDate &&
    +basedateMonth === +dateMonth &&
    +basedateYear === +dateYear
  )
}

export const getPreviousMonth = (month, year) => {
  const prevMonth = month > 1 ? month - 1 : 12
  const prevMonthYear = month > 1 ? year : year - 1

  return { month: prevMonth, year: prevMonthYear }
}

export const getNextMonth = (month, year) => {
  const nextMonth = month < 12 ? month + 1 : 1
  const nextMonthYear = month < 12 ? year : year + 1

  return { month: nextMonth, year: nextMonthYear }
}

export const getWeekDayDatesFromStart = start => {
  const weekDates = [start]
  const d = new Date(start)

  Array(6)
    .fill(null)
    .forEach(() => weekDates.push(getDateISO(new Date(d.setDate(d.getDate() + 1)))))

  return weekDates
}

const WEEK_DAYS_MAP = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6
}

export const getDaysInDates = (s, e, days) => {
  const start = new Date(s)
  const end = new Date(e)

  if (end < start || days.length === 0) return 0

  let count = 0

  for (count; start <= end; start.setDate(start.getDate() + 1)) {
    // eslint-disable-next-line no-loop-func
    Object.keys(WEEK_DAYS).forEach(day => {
      if (
        start.getDay() === WEEK_DAYS_MAP[WEEK_DAYS[day]] &&
        days.includes(WEEK_DAYS[day])
      ) {
        count += 1
      }
    })
  }

  return count
}

export const getDaysInBetweenCount = (_date1, _date2) => {
  const date1 = moment(_date1)
  const date2 = moment(_date2)

  // +1 to include the start
  return date2.diff(date1, 'days') + 1
}

export const getDaysInBetweenArray = (_startDate, _stopDate) => {
  const dateArray = []
  let currentDate = moment(_startDate)
  const stopDate = moment(_stopDate)

  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).toDate())
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

export const getDayOfWeek = date => STANDARD_FULL_WEEK_DAYS[moment(date).day()]

export const shortenWeekDay = day => WEEK_DAYS[day]

export const turnWeekDaysToBinary = days => {
  const weekDays = Object.values(WEEK_DAYS)

  days.forEach(day => {
    const index = weekDays.findIndex(item => item === day)
    weekDays[index] = '1'
  })

  return weekDays.map(day => (day === '1' ? '1' : '0')).join('')
}

export const removeNonBusinessDays = daysArr => {
  const nonBusinessDays = ['Saturday', 'Sunday', 'Sat', 'Sun']
  return daysArr.filter(day => !nonBusinessDays.includes(day))
}

export default (month = THIS_MONTH, year = THIS_YEAR) => {
  const monthDays = getMonthDays(month, year)
  const monthFirstDay = getMonthFirstDay(month, year) + 6

  let daysFromPrevMonth = monthFirstDay - 1
  let daysFromNextMonth = CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays)

  if (daysFromNextMonth < 0) {
    daysFromPrevMonth -= 7
    daysFromNextMonth = CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays)
  }

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year)
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year)

  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear)

  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth)
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)]
  })

  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1
    return [year, zeroPad(month, 2), zeroPad(day, 2)]
  })

  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)]
  })

  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates]
}
