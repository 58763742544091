import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="history__a"
          // eslint-disable-next-line
          d="M12.964 2c-4.04 0-7.56 2.29-9.274 5.641l-.415-.745c-.217-.4-.64-.644-1.094-.632a1.208 1.208 0 00-1.02 1.811l1.661 2.963a1.208 1.208 0 001.604.49l2.953-1.51a1.208 1.208 0 00-.981-2.197 7.993 7.993 0 016.566-3.406c4.425 0 7.971 3.509 7.971 7.85 0 4.304-3.494 7.791-7.867 7.848a1.208 1.208 0 10.037 2.415c5.651-.073 10.246-4.646 10.246-10.264C23.35 6.601 18.677 2 12.964 2zm.104 4.208a1.208 1.208 0 00-1.19 1.226v4.83c0 .367.167.714.454.944l3.019 2.415a1.208 1.208 0 101.509-1.887l-2.566-2.057V7.434a1.208 1.208 0 00-1.226-1.226z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="history__b" fill="#fff">
          <use xlinkHref="#history__a" />
        </mask>
        <g fill="#6B75FF" mask="url(#history__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
