import {
  SET_PROJECTS_LIST,
  SET_ROUTE,
  SET_BILLING_RATE_CHANGED
} from '../actions/app'

const initialState = {
  projects: [],
  route: null,
  isMemberBillingRateChanged: false
}

function userReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case SET_PROJECTS_LIST:
      return {
        ...state,
        projects: action.payload
      }

    case SET_ROUTE:
      return {
        ...state,
        route: action.payload
      }

    case SET_BILLING_RATE_CHANGED:
      return {
        ...state,
        isMemberBillingRateChanged: action.payload
      }

    default:
      return state
  }
}

export default userReducer
