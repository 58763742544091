import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <path
          id="info__8ik6rwdc3a"
          /* eslint-disable-next-line max-len */
          d="M10 .833c5.063 0 9.167 4.104 9.167 9.167S15.063 19.167 10 19.167.833 15.063.833 10 4.937.833 10 .833zM10 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm0 6.667c.46 0 .833.373.833.833v3.333a.834.834 0 11-1.666 0V10c0-.46.373-.833.833-.833zm0-3.334A.833.833 0 1110 7.5a.833.833 0 010-1.667z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fillRule="nonzero" d="M0 0h20v20H0z" />
        <mask id="info__e3x9dnrn9b" fill="#fff">
          <use xlinkHref="#info__8ik6rwdc3a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#info__8ik6rwdc3a" />
        <g fill="#000" mask="url(#info__e3x9dnrn9b)">
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
