/* eslint-disable max-len */
import React from 'react'

function Icon() {
  return (
    <svg width="122" height="77.36" viewBox="-6.25 -6.25 762.5 483.5">
      <path
        fill="#FFF"
        d="M617.242 346.766c0 41.615-33.729 75.36-75.357 75.36H132.759V124.245c0-41.626 33.73-75.371 75.364-75.371h409.12v297.892h-.001z"
      />
      <linearGradient
        id="cfa"
        gradientUnits="userSpaceOnUse"
        x1="824.742"
        y1="333.781"
        x2="825.742"
        y2="333.781"
        gradientTransform="matrix(132.8743 0 0 -323.0226 -109129.531 108054.602)"
      >
        <stop offset="0" stopColor="#007b40" />
        <stop offset="1" stopColor="#55b330" />
      </linearGradient>
      <path
        fill="url(#cfa)"
        d="M483.86 242.045c11.686.254 23.439-.516 35.078.4 11.787 2.199 14.627 20.043 4.156 25.887-7.145 3.85-15.633 1.434-23.379 2.113H483.86v-28.4zm41.834-32.145c2.596 9.164-6.238 17.392-15.064 16.13h-26.77c.188-8.642-.367-18.022.273-26.209 10.723.302 21.547-.616 32.209.48 4.58 1.151 8.414 4.917 9.352 9.599zm64.425-135.903c.498 17.501.072 35.927.215 53.783-.033 72.596.07 145.195-.057 217.789-.469 27.207-24.582 50.847-51.6 51.39-27.045.11-54.094.017-81.143.047v-109.75c29.471-.153 58.957.308 88.416-.231 13.666-.858 28.635-9.875 29.271-24.914 1.609-15.103-12.631-25.551-26.152-27.201-5.197-.135-5.045-1.515 0-2.117 12.895-2.787 23.021-16.133 19.227-29.499-3.234-14.058-18.771-19.499-31.695-19.472-26.352-.179-52.709-.025-79.063-.077.17-20.489-.355-41 .283-61.474 2.088-26.716 26.807-48.748 53.447-48.27 26.287-.003 52.57-.003 78.851-.004z"
      />
      <linearGradient
        id="cfb"
        gradientUnits="userSpaceOnUse"
        x1="824.755"
        y1="333.782"
        x2="825.748"
        y2="333.782"
        gradientTransform="matrix(133.4307 0 0 -323.0203 -109887.688 108053.82)"
      >
        <stop offset="0" stopColor="#1d2970" />
        <stop offset="1" stopColor="#006dba" />
      </linearGradient>
      <path
        fill="url(#cfb)"
        d="M159.742 125.041c.673-27.164 24.888-50.611 51.872-51.008 26.945-.083 53.894-.012 80.839-.036-.074 90.885.146 181.776-.111 272.657-1.038 26.834-24.989 49.834-51.679 50.309-26.996.098-53.995.014-80.992.041V283.551c26.223 6.195 53.722 8.832 80.474 4.723 15.991-2.574 33.487-10.426 38.901-27.016 3.984-14.191 1.741-29.126 2.334-43.691v-33.825h-46.297c-.208 22.371.426 44.781-.335 67.125-1.248 13.734-14.849 22.46-27.802 21.994-16.064.17-47.897-11.641-47.897-11.641-.08-41.915.466-94.406.693-136.179z"
      />
      <linearGradient
        id="cfc"
        gradientUnits="userSpaceOnUse"
        x1="824.742"
        y1="333.781"
        x2="825.741"
        y2="333.781"
        gradientTransform="matrix(132.9583 0 0 -323.0276 -109347.922 108056.266)"
      >
        <stop offset="0" stopColor="#6e2b2f" />
        <stop offset="1" stopColor="#e30138" />
      </linearGradient>
      <path
        fill="url(#cfc)"
        d="M309.721 197.39c-2.437.517-.491-8.301-1.114-11.646.166-21.15-.346-42.323.284-63.458 2.082-26.829 26.991-48.916 53.738-48.288h78.767c-.074 90.885.145 181.775-.111 272.657-1.039 26.834-24.992 49.833-51.682 50.309-26.998.101-53.998.015-80.997.042V272.707c18.44 15.129 43.5 17.484 66.472 17.525 17.318-.006 34.535-2.676 51.353-6.67V260.79c-18.953 9.446-41.234 15.446-62.244 10.019-14.656-3.649-25.294-17.813-25.057-32.937-1.698-15.729 7.522-32.335 22.979-37.011 19.192-6.008 40.108-1.413 58.096 6.398 3.855 2.018 7.766 4.521 6.225-1.921v-17.899c-30.086-7.158-62.104-9.792-92.33-2.005-8.748 2.468-17.272 6.211-24.379 11.956z"
      />
    </svg>
  )
}

export default Icon
