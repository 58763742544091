import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import MaterialModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'

import s from './Modal.module.scss'

function Modal({ open, onClose, children, classes }) {
  const BackdropComponent = props => (
    <Backdrop className={cx(s.backdrop, classes.backdrop)} {...props} />
  )

  return (
    <div>
      <MaterialModal
        className={cx(s.modal, classes.modal)}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={BackdropComponent}
        BackdropProps={{ timeout: 300 }}
      >
        <Fade in={open}>
          <Paper className={cx(s.paper, classes.paper)}>
            <button
              type="button"
              className={cx(s.closeIcon, classes.closeIcon)}
              onClick={onClose}
            >
              <div className="icon-Close" />
            </button>
            {children}
          </Paper>
        </Fade>
      </MaterialModal>
    </div>
  )
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    backdrop: PropTypes.string,
    modal: PropTypes.string,
    paper: PropTypes.string,
    closeIcon: PropTypes.string
  })
}

Modal.defaultProps = {
  classes: {
    backdrop: '',
    modal: '',
    paper: '',
    closeIcon: ''
  }
}

export default Modal
