import {
  SET_LOADING,
  SET_OVERVIEW,
  SET_PLANS_LIST,
  IS_SHOW_PLANS_POPUP,
  OPEN_POPUP_TYPE,
  SET_ADMIN_MEMBERS
} from '../actions/subscription'

export const initialState = {
  loading: true,
  overview: null,
  plansList: null,
  isShowPlan: false,
  openPopupType: '', // contact || change-member-number-pro || switch-yearly-pro
  adminMembers: null
}

function subscriptionReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }

    case SET_OVERVIEW:
      return { ...state, overview: action.payload }

    case SET_PLANS_LIST:
      return { ...state, plansList: action.payload }

    case IS_SHOW_PLANS_POPUP:
      return { ...state, isShowPlan: action.payload }

    case OPEN_POPUP_TYPE:
      return { ...state, openPopupType: action.payload }

    case SET_ADMIN_MEMBERS:
      return { ...state, adminMembers: action.payload }

    default:
      return state
  }
}

export default subscriptionReducer
