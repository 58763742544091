import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="tag_aaa"
          /* eslint-disable-next-line max-len */
          d="M11.008 1a.796.796 0 01.595.24l10.439 10.425a3.26 3.26 0 010 4.616l-5.753 5.76a3.257 3.257 0 01-4.616 0L1.24 11.604a.818.818 0 01-.239-.595l.18-7.7a2.17 2.17 0 012.128-2.128zm-.296 1.624l-7.36.171c-.3 0-.543.243-.543.543l-.171 7.36 10.186 10.187a1.63 1.63 0 002.305 0l5.756-5.756a1.627 1.627 0 000-2.305l-10.173-10.2zM5.913 5.913a2.985 2.985 0 014.223-.001 2.968 2.968 0 010 4.225 2.987 2.987 0 01-4.223-4.224zm2.971 1.061a1.358 1.358 0 10.102.091z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fillRule="nonzero" d="M0 0h24v24H0z" />
        <mask id="tag_bbb" fill="#fff">
          <use xlinkHref="#tag_aaa" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#tag_aaa" />
        <g fill="#6B75FF" mask="url(#tag_bbb)">
          <path d="M24 0v24H0V0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
