import TimeEntry from 'assets/images/sidebar/time-entry.svg'
import TimeApproval from 'assets/images/sidebar/time-approval.svg'
import Planning from 'assets/images/sidebar/planning.svg'
import Invoicing from 'assets/images/sidebar/invoicing.svg'
import Reports from 'assets/images/sidebar/reports.svg'
import PersonalProfile from 'assets/images/sidebar/profile.svg'
import Settings from 'assets/images/sidebar/settings.svg'
import Dashboard from 'assets/images/sidebar/dashboard.svg'

export default {
  app: [
    {
      icon: Dashboard,
      title: 'Dashboard',
      path: '/dashboard',
      permission: [],
      disabled: false
    },
    {
      icon: TimeEntry,
      title: 'Time Entry',
      path: '/time-entry',
      permission: ['view-TimeSheet-self'],
      disabled: true
    },
    {
      icon: TimeApproval,
      title: 'Time Approval',
      path: '/time-approval',
      permission: [
        'view-ProjectTimeEntry-projectGroup',
        'view-OffTimeEntry-teamGroup',
        'view-ProjectTimeEntry-group',
        'view-OffTimeEntry-group'
      ],
      disabled: true
    },
    {
      icon: Planning,
      title: 'Planning',
      path: '/planning',
      permission: [
        'view-TimePlan-projectGroup',
        'view-TimePlan-projectSelf',
        'view-TimeOff-teamGroup',
        'view-TimeOff-teamSelf',
        'view-TimePlan-group',
        'view-TimeOff-group'
      ],
      disabled: false
    },
    {
      icon: Invoicing,
      title: 'Invoicing',
      path: '/invoice',
      permission: ['view-Invoice-group'],
      disabled: true
    },
    {
      icon: Reports,
      title: 'Reporting',
      path: '/reports',
      permission: ['view-Client-group'],
      disabled: true
    }
  ],
  user: [
    {
      icon: Settings,
      title: 'Settings',
      path: '/settings',
      permission: [
        'view-Project-group',
        'view-User-group',
        'view-Client-group',
        'view-Subscription-group',
        'view-Company-self',
        'view-Invoice-group'
      ],
      disabled: false
    },
    {
      icon: PersonalProfile,
      title: 'My Profile',
      path: '/profile',
      permission: [],
      disabled: false
    }
  ]
}
