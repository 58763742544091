// Types
export const SET_YEAR_AND_STEP = 'SET_YEAR_AND_STEP'
export const SET_MEMBER_ID = 'SET_MEMBER_ID'
export const UPDATE_TIMESHEET_PROJECTS = 'UPDATE_TIMESHEET_PROJECTS'
export const SET_TIMESHEET_PROJECTS = 'SET_TIMESHEET_PROJECTS'
export const SET_TIMESHEET_ID = 'SET_TIMESHEET_ID'
export const SET_TIMESHEET_STATUS = 'SET_TIMESHEET_STATUS'
export const UPDATE_PROJECTS_WITH_NEW = 'UPDATE_PROJECTS_WITH_NEW'
export const SET_ALL_TYPES_PROJECTS = 'SET_ALL_TYPES_PROJECTS'
export const ADD_TIME_OFF_TO_PROJECTS = 'ADD_TIME_OFF_TO_PROJECTS'
export const UPDATE_TIMESHEET_PROJECT_ITEM = 'UPDATE_TIMESHEET_PROJECT_ITEM'
export const SET_SUBMIT_TYPE = 'SET_SUBMIT_TYPE'
export const ADD_DELETED_ROW_ID = 'ADD_DELETED_ROW_ID'
export const SET_UNHANDLED_EDIT = 'SET_UNHANDLED_EDIT'
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL'

// Creators
export const setYearAndStep = payload => ({ type: SET_YEAR_AND_STEP, payload })

export const setMemberId = payload => ({ type: SET_MEMBER_ID, payload })

export const updateTimesheetProjects = payload => ({
  type: UPDATE_TIMESHEET_PROJECTS,
  payload
})

export const setTimesheetProjects = payload => ({
  type: SET_TIMESHEET_PROJECTS,
  payload
})

export const setTimesheetId = payload => ({ type: SET_TIMESHEET_ID, payload })

export const setTimesheetStatus = payload => ({ type: SET_TIMESHEET_STATUS, payload })

export const updateProjectsWithNew = payload => ({
  type: UPDATE_PROJECTS_WITH_NEW,
  payload
})

export const setAllTypesProjects = payload => ({
  type: SET_ALL_TYPES_PROJECTS,
  payload
})

export const addTimeOffToProjects = payload => ({
  type: ADD_TIME_OFF_TO_PROJECTS,
  payload
})

export const updateTimesheetProjectItem = payload => ({
  type: UPDATE_TIMESHEET_PROJECT_ITEM,
  payload
})

export const setSubmitType = payload => ({ type: SET_SUBMIT_TYPE, payload })

export const addDeletedRowId = payload => ({ type: ADD_DELETED_ROW_ID, payload })

// unhandled Edit

export const setunhandledEdit = payload => ({ type: SET_UNHANDLED_EDIT, payload })

export const setShowModalTimeEntry = payload => ({ type: SET_SHOW_MODAL, payload })
