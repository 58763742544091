import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import ga from 'libs/ga'

const AppContainer = ({ children }) => {
  const {
    location: { pathname, search }
  } = useHistory()

  useEffect(() => {
    ga.pageview(pathname + search)
  }, [pathname, search])

  return children
}

AppContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppContainer
