// Types
export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST'
export const SET_ROUTE = 'SET_ROUTE'
export const SET_BILLING_RATE_CHANGED = 'SET_BILLING_RATE_CHANGED'

// Creators
export const setProjectsList = projects => ({
  type: SET_PROJECTS_LIST,
  payload: projects
})

export const setRoute = payload => ({
  type: SET_ROUTE,
  payload
})

export const memberBillingRateChanged = payload => ({
  type: SET_BILLING_RATE_CHANGED,
  payload
})
