import React from 'react'
import PropTypes from 'prop-types'

import LanguageContext from './context'

/**
 * @todo implement change language
 */
const LanguageProvider = ({ children }) => (
  <LanguageContext.Provider value="en">
    {children}
  </LanguageContext.Provider>
)

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default LanguageProvider
