import { createBrowserHistory, createMemoryHistory } from 'history'
import ROUTES from 'routes/routes'

const isTest = process.env.NODE_ENV === 'test'
const memoryInitialRoutes = [
  { pathname: ROUTES.SIGNIN, key: 'SIGNIN' },
  { pathname: ROUTES.HOME_PATH, key: 'HOME' },
  { pathname: ROUTES.PROFILE, key: 'PROFILE' },
  { pathname: ROUTES.SIGNUP, key: 'SIGNUP' },
  { pathname: ROUTES.CONFIRMATION_CODE, key: 'CONFIRMATION_CODE' }
]

const history = isTest
  ? createMemoryHistory({ initialEntries: memoryInitialRoutes })
  : createBrowserHistory()

export default history
