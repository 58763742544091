export const AUTH_ROUTES = {
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password',
  CONFIRMATION_CODE: '/confirmation-code'
}

const ROUTES = {
  BASE: '/',
  HOME_PATH: '/home',
  PLANNING: '/planning',
  ASSISTANT: '/assistant',
  INVOICE: '/invoice',
  INVOICE_NEW: '/invoice/new',
  INVOICE_EDIT: '/invoice/edit/:invoiceId',
  INVOICE_ITEM: '/invoice/item/:invoiceId',
  INVOICE_HISTORY: '/invoice/history/:invoiceId',
  TIME_ENTRY: '/time-entry',
  TIME_APPROVAL: '/time-approval',
  TIME_OFF_APPROVAL: '/time-off-approval',
  REPORTS: '/reports',
  SETTINGS: '/settings',
  INTEGRATIONS: '/settings/integrations',
  INTEGRATIONS_HISTORY: '/settings/integrations/history',
  ACTIVITY_LOG: '/settings/activityLog',
  CLIENTS: '/settings/clients',
  CLIENTS_ADD: '/settings/clients/add',
  CLIENTS_EDIT: '/settings/clients/edit/:id',
  PROJECTS: '/settings/projects',
  PROJECTS_ADD: '/settings/projects/add',
  PROJECTS_EDIT: '/settings/projects/edit/:id',
  MEMBERS: '/settings/members',
  MEMBERS_ADD: '/settings/members/add',
  MEMBERS_EDIT: '/settings/members/edit/:id',
  PROJECT_ROLES: '/settings/project-roles',
  TAGS: '/settings/tags',
  COMPANY: '/settings/company',
  INVOICE_SETTING: '/settings/invoice',
  SUBSCRIPTION: '/settings/subscription',
  SUBSCRIPTION_BILLING: '/settings/subscription/billing',
  SUBSCRIPTION_INVOICES: '/settings/subscription/invoices',
  PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  ONBOARDING: '/onboarding',
  ...AUTH_ROUTES
}

export const EXTERNAL_LINKS = {
  WEBSITE: 'https://bisflow.com'
}

export const ROUTES_HAVE_SIDEBAR = [
  ROUTES.HOME_PATH,
  ROUTES.DASHBOARD,
  ROUTES.PLANNING,
  ROUTES.ASSISTANT,
  ROUTES.INVOICE,
  ROUTES.TIME_ENTRY,
  ROUTES.TIME_APPROVAL,
  ROUTES.TIME_OFF_APPROVAL,
  ROUTES.REPORTS,
  ROUTES.SETTINGS,
  ROUTES.PROFILE
]

export const ROUTES_HAVE_HEADER_BACK_BUTTON = [
  ROUTES.INVOICE_NEW,
  ROUTES.INVOICE_EDIT,
  ROUTES.INVOICE_ITEM,
  ROUTES.INVOICE_HISTORY,
  ROUTES.CLIENTS,
  ROUTES.CLIENTS_ADD,
  ROUTES.CLIENTS_EDIT,
  ROUTES.PROJECTS,
  ROUTES.PROJECTS_ADD,
  ROUTES.PROJECTS_EDIT,
  ROUTES.MEMBERS,
  ROUTES.MEMBERS_ADD,
  ROUTES.MEMBERS_EDIT,
  ROUTES.PROJECT_ROLES,
  ROUTES.TAGS,
  ROUTES.COMPANY,
  ROUTES.INVOICE_SETTING
]

export default ROUTES
