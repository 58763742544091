/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { SnackbarProvider } from 'notistack'
import { AnimatedSwitch } from 'react-router-transition'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CookieConsent from 'react-cookie-consent'
import ReactGA from 'react-ga'

import ThemeProvider from 'theme'
import LanguageProvider from 'contexts/language/provider'
import { Provider as ReportingProvider } from 'contexts/ReportingContext'
import { Provider as AuthProvider } from 'contexts/AuthContext'
import { Provider as GlobalProvider } from 'contexts/GlobalContext'
import { persistor, store } from 'store/config'
import history from 'libs/history'
import { useStore, AppContainer } from 'hooks'
import { Snackbar } from 'libs/snack'
import ModalConfirm from 'components/ModalConfirm'
import SideBar from 'components/SideBar'
import Header from 'components/Header'
import HeaderBackButton from 'components/HeaderBackButton'
import InitialLoading from 'components/InitialLoading'
// import ErrorBoundary from 'components/ErrorBoundary'

import useTranslate from 'hooks/useTranslate'
import ROUTES from './routes'
import {
  AsyncSignin,
  AsyncSignup,
  AsyncAssistant,
  AsyncClients,
  AsyncClientsAdd,
  AsyncCompany,
  AsyncConfirmationCode,
  AsyncOnboarding,
  AsyncForgetPassword,
  AsyncHome,
  AsyncInvoiceSetting,
  AsyncInvoicing,
  AsyncInvoicingHistory,
  AsyncInvoicingItem,
  AsyncInvoicingNew,
  AsyncMembersAdd,
  AsyncMembers,
  AsyncPlanning,
  AsyncProfile,
  AsyncProjectRoles,
  AsyncProjects,
  AsyncProjectsAdd,
  AsyncReports,
  AsyncResetPassword,
  AsyncSettings,
  AsyncSubscription,
  AsyncActivityLog,
  // AsyncSubscriptionBilling,
  AsyncSubscriptionInvoices,
  AsyncTags,
  AsyncTimeApproval,
  AsyncTimeEntry,
  AsyncDashboard,
  AsyncIntegrations,
  AsyncIntegrationsHistory
} from './pages'
import NoMatch from './404'
import { mapStyles, bounceTransition, snackbarClasses, isLoggedIn } from './utils'

const stripePromise = loadStripe(
  'pk_test_51HIu1NI5zVJJpkaULhDYVCIDOAOmxbk6H1MQpdD0DvD20xXE6SDWglDt8EuCMxdKj80BbnzNlqszyxEKa8x6lJGl00x2BxU5SK'
)

const RouterConfig = () => {
  const [loading, setLoading] = useState(false)
  const [fulfilledRequestsCount, setFulfilledRequestsCount] = useState(0)
  const loggedIn = isLoggedIn()
  const t = useTranslate('general')
  const { getUserInfo } = useStore('AuthContext')
  const {
    getRegularPermissions,
    getProjectPermissions,
    getTeamPermissions,
    getCountries,
    getTimezones
  } = useStore('GlobalContext')
  const PLANNING_HEADER_LINKS = [
    { title: t('headers.0'), path: ROUTES.PLANNING },
    { title: t('headers.1'), path: ROUTES.ASSISTANT }
  ]

  const SUBSCRIPTION_HEADER_LINKS = [
    { title: t('headers.2'), path: ROUTES.SUBSCRIPTION },
    // { title: 'Billing settings', path: ROUTES.SUBSCRIPTION_BILLING },
    { title: t('headers.3'), path: ROUTES.SUBSCRIPTION_INVOICES }
  ]

  const APPROVAL_HEADER_LINKS = [
    // { title: 'Timesheets', path: ROUTES.TIME_APPROVAL }
    // { title: 'Time-off Requests', path: ROUTES.TIME_OFF_APPROVAL }
  ]

  const initApp = async () => {
    const incrementFulfilledReqCounts = () => {
      setFulfilledRequestsCount(currentValue => currentValue + 1)
    }

    try {
      setLoading(true)
      await getUserInfo()

      incrementFulfilledReqCounts()

      await Promise.allSettled([
        getRegularPermissions().then(incrementFulfilledReqCounts),
        getProjectPermissions().then(incrementFulfilledReqCounts),
        getTeamPermissions().then(incrementFulfilledReqCounts)
      ])

      Promise.all([getCountries(), getTimezones()])

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (loggedIn) {
      initApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <InitialLoading progress={fulfilledRequestsCount} />
  }

  return (
    // <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <LanguageProvider>
          <ThemeProvider>
            <SnackbarProvider maxSnack={5} classes={snackbarClasses}>
              <Snackbar />

              <ModalConfirm />

              <Router history={history}>
                <SideBar />

                <AppContainer>
                  <Switch>
                    <Route exact path={ROUTES.BASE}>
                      {loggedIn ? (
                        <Redirect to={ROUTES.HOME_PATH} />
                      ) : (
                        <Redirect to={ROUTES.SIGNIN} />
                      )}
                    </Route>

                    <Route exact path={ROUTES.SIGNIN}>
                      <AsyncSignin />
                    </Route>

                    <Route exact path={ROUTES.SIGNUP}>
                      <AsyncSignup />
                    </Route>

                    <Route exact path={ROUTES.FORGET_PASSWORD}>
                      <AsyncForgetPassword />
                    </Route>

                    <Route exact path={ROUTES.RESET_PASSWORD}>
                      <AsyncResetPassword />
                    </Route>

                    <Route exact path={ROUTES.CONFIRMATION_CODE}>
                      <AsyncConfirmationCode />
                    </Route>

                    <Route exact path={ROUTES.ONBOARDING}>
                      <AsyncOnboarding />
                    </Route>

                    <Protected>
                      <AnimatedSwitch
                        atEnter={bounceTransition.atEnter}
                        atLeave={bounceTransition.atLeave}
                        atActive={bounceTransition.atActive}
                        mapStyles={mapStyles}
                        className="route-wrapper"
                      >
                        <Route exact path={ROUTES.HOME_PATH}>
                          <AsyncHome />
                        </Route>

                        <Route exact path={ROUTES.DASHBOARD}>
                          <AsyncDashboard />
                        </Route>

                        <Route exact path={ROUTES.PLANNING}>
                          <Header
                            links={PLANNING_HEADER_LINKS}
                            backButton={false}
                            sticky={false}
                          />
                          <AsyncPlanning />
                        </Route>

                        <Route exact path={ROUTES.ASSISTANT}>
                          <Header
                            links={PLANNING_HEADER_LINKS}
                            backButton={false}
                            sticky={false}
                          />
                          <AsyncAssistant />
                        </Route>

                        <Route exact path={ROUTES.INVOICE}>
                          <AsyncInvoicing />
                        </Route>

                        <Route exact path={ROUTES.INVOICE_NEW}>
                          <HeaderBackButton />
                          <AsyncInvoicingNew />
                        </Route>

                        <Route exact path={ROUTES.INVOICE_EDIT}>
                          <HeaderBackButton />
                          <AsyncInvoicingNew />
                        </Route>

                        <Route exact path={ROUTES.INVOICE_ITEM}>
                          <HeaderBackButton />
                          <AsyncInvoicingItem />
                        </Route>

                        <Route exact path={ROUTES.INVOICE_HISTORY}>
                          <HeaderBackButton />
                          <AsyncInvoicingHistory />
                        </Route>

                        <Route exact path={ROUTES.TIME_ENTRY}>
                          <AsyncTimeEntry />
                        </Route>

                        <Route exact path={ROUTES.TIME_APPROVAL}>
                          <Header
                            links={APPROVAL_HEADER_LINKS}
                            backButton={false}
                            sticky={false}
                          />
                          <AsyncTimeApproval />
                        </Route>

                        <Route exact path={ROUTES.TIME_OFF_APPROVAL}>
                          <Header links={APPROVAL_HEADER_LINKS} sticky={false} />
                        </Route>

                        <Route exact path={ROUTES.REPORTS}>
                          <ReportingProvider>
                            <AsyncReports />
                          </ReportingProvider>
                        </Route>

                        <Route exact path={ROUTES.SETTINGS}>
                          <AsyncSettings />
                        </Route>

                        {/* Clients */}
                        <Route exact path={ROUTES.CLIENTS}>
                          <HeaderBackButton />
                          <AsyncClients />
                        </Route>

                        <Route exact path={ROUTES.CLIENTS_ADD}>
                          <HeaderBackButton />
                          <AsyncClientsAdd />
                        </Route>

                        <Route exact path={ROUTES.CLIENTS_EDIT}>
                          <HeaderBackButton />
                          <AsyncClientsAdd />
                        </Route>

                        {/* Projects */}
                        <Route exact path={ROUTES.PROJECTS}>
                          <HeaderBackButton />
                          <AsyncProjects />
                        </Route>

                        <Route exact path={ROUTES.PROJECTS_ADD}>
                          <HeaderBackButton />
                          <AsyncProjectsAdd />
                        </Route>

                        <Route exact path={ROUTES.PROJECTS_EDIT}>
                          <HeaderBackButton />
                          <AsyncProjectsAdd />
                        </Route>

                        {/* Members */}
                        <Route exact path={ROUTES.MEMBERS}>
                          <HeaderBackButton />
                          <AsyncMembers />
                        </Route>

                        <Route exact path={ROUTES.MEMBERS_ADD}>
                          <HeaderBackButton />
                          <AsyncMembersAdd />
                        </Route>

                        <Route exact path={ROUTES.MEMBERS_EDIT}>
                          <HeaderBackButton />
                          <AsyncMembersAdd />
                        </Route>

                        {/* Project roles */}
                        <Route exact path={ROUTES.PROJECT_ROLES}>
                          <HeaderBackButton />
                          <AsyncProjectRoles />
                        </Route>

                        {/* Tags */}
                        <Route exact path={ROUTES.TAGS}>
                          <HeaderBackButton />
                          <AsyncTags />
                        </Route>

                        {/* Profile */}
                        <Route exact path={ROUTES.PROFILE}>
                          <AsyncProfile />
                        </Route>

                        {/* Setting Subscription */}

                        <Route exact path={ROUTES.SUBSCRIPTION}>
                          <Header links={SUBSCRIPTION_HEADER_LINKS} isSubscription />
                          <AsyncSubscription />
                        </Route>

                        {/* <Route exact path={ROUTES.SUBSCRIPTION_BILLING}>
                            <AsyncSubscriptionBilling />
                          </Route> */}

                        <Route exact path={ROUTES.SUBSCRIPTION_INVOICES}>
                          <Header links={SUBSCRIPTION_HEADER_LINKS} isSubscription />
                          <AsyncSubscriptionInvoices />
                        </Route>

                        {/* Setting Company */}
                        <Route exact path={ROUTES.COMPANY}>
                          <HeaderBackButton />
                          <AsyncCompany />
                        </Route>

                        {/* Setting Integrations */}
                        <Route exact path={ROUTES.INTEGRATIONS}>
                          <HeaderBackButton />
                          <AsyncIntegrations />
                        </Route>

                        {/* Integrations Invoice history */}
                        <Route exact path={ROUTES.INTEGRATIONS_HISTORY}>
                          <HeaderBackButton />
                          <AsyncIntegrationsHistory />
                        </Route>

                        {/* Setting Activity log */}
                        <Route exact path={ROUTES.ACTIVITY_LOG}>
                          <HeaderBackButton />
                          <AsyncActivityLog />
                        </Route>

                        {/* Setting Invoice */}
                        <Route exact path={ROUTES.INVOICE_SETTING}>
                          <HeaderBackButton />
                          <AsyncInvoiceSetting />
                        </Route>
                      </AnimatedSwitch>
                    </Protected>

                    <Route path="*">
                      <NoMatch />
                    </Route>
                  </Switch>
                </AppContainer>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </LanguageProvider>
      </PersistGate>
    </Provider>
    // </ErrorBoundary>
  )
}

const App = () => {
  const { REACT_APP_GA_TRACKING_ID } = process.env

  const initGa = () => {
    ReactGA.initialize(REACT_APP_GA_TRACKING_ID)
  }

  const handleAcceptCookie = () => {
    if (REACT_APP_GA_TRACKING_ID) {
      initGa()
    }
  }

  useEffect(() => {
    initGa()
  }, [])

  return (
    <GlobalProvider>
      <AuthProvider>
        <Elements stripe={stripePromise}>
          <RouterConfig />
          <CookieConsent
            onAccept={handleAcceptCookie}
            buttonText="Accept all Cookies"
            style={{
              background: '#2e3a59',
              fontSize: '18px',
              width: '500px',
              height: '200px',
              flexFlow: 'unset',
              borderRadius: '16px',
              left: '50%',
              transform: 'translate(-50%,-5%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexDirection: 'column'
            }}
            contentStyle={{ flex: 'unset', textAlign: 'center' }}
            buttonStyle={{
              background: '#6b75ff',
              borderRadius: '4px',
              color: '#fff',
              padding: '14px 24px',
              fontSize: '18px'
            }}
          >
            This website uses cookies to enhance the user experience.Read more about
            our{' '}
            <a href="google.com" style={{ color: '#6f96e1' }}>
              cookie policy
            </a>
          </CookieConsent>
        </Elements>
      </AuthProvider>
    </GlobalProvider>
  )
}

function Protected({ children }) {
  const loggedIn = isLoggedIn()

  if (loggedIn) return children

  return <Redirect to={ROUTES.SIGNIN} />
}

Protected.propTypes = {
  children: PropTypes.node.isRequired
}

export default App
