import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import { normalize } from 'libs/utils'

function Loading({ show, className, size }) {
  if (show) {
    return (
      <div className={className}>
        <CircularProgress
          style={{
            width: normalize(size, 'vh'),
            height: normalize(size, 'vh')
          }}
        />
      </div>
    )
  }

  return null
}

Loading.propTypes = {
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
  size: PropTypes.number
}

Loading.defaultProps = {
  size: 30,
  className: ''
}

export default Loading
