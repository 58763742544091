import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import s from './MenuLogo.module.scss'

function MenuLogo({ expand }) {
  return (
    <div className={cx({ [s.logoExpand]: expand }, { [s.logo]: !expand })}>
      <span className={cx({ [s.sec1]: expand }, { [s.sec1Close]: !expand })}>B</span>
      <span className={s.sec2}>is</span>
      <span className={cx({ [s.sec3]: expand }, { [s.sec3Close]: !expand })}>F</span>
      <span className={s.sec4}>low</span>
    </div>
  )
}
MenuLogo.propTypes = {
  expand: PropTypes.bool
}

MenuLogo.defaultProps = {
  expand: true
}
export default MenuLogo
