function debug(title, url, body, method, headers) {
  /* eslint-disable */
  if (process.env.NODE_ENV === 'development') {
    const d = new Date()
    if (console.groupCollapsed) {
      console.groupCollapsed(title, `@ ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`)
    } else {
      console.log(title, `@ ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`)
    }

    if (method) console.log('%c method: ', 'color: #e74c3c; font-weight: bold', method)
    if (url) console.log('%c endpoint: ', 'color: #2ecc71; font-weight: bold', url)
    if (body) console.log('%c body: ', 'color: #3498db; font-weight: bold', body)
    if (headers) console.log('%c headers: ', 'color: #e67e22; font-weight: bold', headers)
    if (console.groupEnd) {
      console.groupEnd()
    }
  }
  /* eslint-enable */
}

export default debug
