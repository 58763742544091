/* eslint-disable no-unused-vars */
import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import cx from 'clsx'

import proptypes from 'routes/Subscription/proptypes'

import { removeLastArrayElement, includesDigits } from 'libs/utils'

import useTranslate from 'hooks/useTranslate'
import s from './Header.module.scss'

const getCurrentRouteIndex = (links, pathname) =>
  links.findIndex(link => link.path === pathname)

/**
 * @return {null}
 */
function Header({ links, backButton, sticky, isSubscription, overview }) {
  const [tabs, setTabs] = useState(links)
  const history = useHistory()
  const defaultActiveTab = getCurrentRouteIndex(tabs, history.location.pathname)
  const [activeTab, setActiveTab] = useState(
    defaultActiveTab > 0 ? defaultActiveTab : 0
  )
  const t = useTranslate('general')
  useEffect(() => {
    const newTabIndex = getCurrentRouteIndex(tabs, history.location.pathname)

    if (newTabIndex >= 0) setActiveTab(newTabIndex)
  }, [tabs, history.location.pathname])

  useEffect(() => {
    if (isSubscription && overview && overview.plan.plan_type === 'free') {
      setTabs(tabs.filter(item => item.title !== 'Invoices'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview])

  if (defaultActiveTab < 0) return null

  const handleTabChange = (_, index) => {
    setActiveTab(index)
    history.push(tabs[index].path)
  }

  const handleBackButton = () => {
    try {
      const { pathname, state } = history.location

      if (state && state.redirectUrl) {
        history.push(state.redirectUrl)
        return
      }

      if (includesDigits(pathname)) {
        history.goBack()
        return
      }

      const currentRouteStack = pathname
        .split('/')
        .filter(Boolean)
        .filter(route => !Number(route))

      if (currentRouteStack.length >= 2) {
        const newRouteStack = removeLastArrayElement(currentRouteStack)
        const newRoutePathname = newRouteStack.join('/')

        history.push(`/${newRoutePathname}`)
      } else {
        history.goBack()
      }
    } catch (err) {
      history.goBack()
    }
  }

  if (isSubscription && overview === null) {
    return null
  }

  return (
    <div className={cx(s.container, sticky ? s.sticky : '')}>
      {backButton ? (
        <button type="button" onClick={handleBackButton} className={s.backButton}>
          <div className="icon-back" />
          <span>{t('back')}</span>
        </button>
      ) : null}

      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="header link tabs"
        classes={{
          root: s.tabs,
          indicator: s.tabIndicator,
          flexContainer: s.tabFlexContainer
        }}
      >
        {tabs.map(link => (
          <Tab
            label={link.title}
            key={link.path}
            classes={{
              root: s.tab,
              selected: s.selectedTab
            }}
          />
        ))}
      </Tabs>
    </div>
  )
}

Header.propTypes = {
  isSubscription: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string
    })
  ).isRequired,
  backButton: PropTypes.bool,
  sticky: PropTypes.bool,
  overview: proptypes.overview
}

Header.defaultProps = {
  isSubscription: false,
  backButton: true,
  sticky: true,
  overview: null
}

const mapStateToProps = state => {
  return {
    overview: state.subscription.overview
  }
}

export default connect(mapStateToProps, null)(memo(Header))
