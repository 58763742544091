// TIMES
export const DEBOUNCE_TIME = 300
export const API_TIMEOUT = 30000
const SECOND = 1000
export const getAuthCountdownTimeout = () => {
  const isTest = process.env.NODE_ENV === 'test'
  if (isTest) {
    return 5 * SECOND
  }
  return 60 * SECOND
}

// APP
export const ORDINARY_NUMBERS = ['First', 'Second', 'Third', 'Fourth', 'Fifth']
export const VAT_LIST = [0, 6, 12, 25] // type is percent

// Error Text
export const ERROR_TEXT = {
  isRequired: 'This field is required',
  isRequiredAll: 'All fields is required',
  isNumber: 'This field must be a number'
}

// Date constants
export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const MONTH_DAYS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

// Storage constants
export const AUTH_TOKEN = 'token'
export const SHOULD_SHOW_PLANS_ON_HOME = 'should_show_plans_on_home'
