import isEmpty from 'lodash.isempty'
import queryString from 'query-string'

import { store } from 'store/config'
import { logout } from 'store/actions/user'
import { serializer } from 'libs/utils'
import SnackManager from 'libs/snack'
import history from 'libs/history'
import { API_TIMEOUT, AUTH_TOKEN } from 'constants/app'
import storage from 'libs/storage'
import ROUTES, { AUTH_ROUTES } from 'routes/routes'

import APIConstants from '../constants'
import { LABELS } from '../constants/global'
import debug from './debug'
import mocker from './mocker'

let requestCounter = 0 // Number each API request (used for debugging)
let abortController = null // { abortId: string, value: AbortController }

function fetcher(
  inputUrl,
  method,
  inputEndpoint,
  mockFunc,
  inputParams = {},
  myResponseType = 'json',
  ignoreErrorCodes = []
) {
  let endpoint = inputEndpoint
  const { params } = inputParams
  let responseType = myResponseType

  return new Promise((resolve, reject) => {
    requestCounter += 1
    const requestNum = requestCounter

    const apiTimedOut = setTimeout(() => reject(LABELS.error.timeOut), API_TIMEOUT)

    if (!method || !endpoint) {
      return reject(new Error('Missing params API Params: method or endpoint'))
    }

    // Build request
    const req = {
      method: method.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    }

    // Delete Content-Type in formData type
    if (inputParams.bodyType === 'formData') delete req.headers['Content-Type']

    // Add Bearer token for all apis (except login endpoint)
    const token = storage.load(AUTH_TOKEN)
    if (endpoint !== APIConstants[0].endpoints[0].url) {
      if (token) req.headers.Authorization = `Bearer ${token}`
    }

    // Add Endpoint Params
    let urlParams = ''
    if (params) {
      // Object - eg. /recipes?title=this&cat=2
      if (typeof params === 'object') {
        // Replace matching params in API routes eg. /recipes/{param}/foo
        Object.keys(params).forEach(param => {
          if (endpoint.includes(`{${param}}`)) {
            endpoint = endpoint.split(`{${param}}`).join(params[param])
            delete params[param]
          }
        })

        // Check if there's still an 'id' prop, /{id}?
        if (params.id !== undefined) {
          if (typeof params.id === 'string' || typeof params.id === 'number') {
            urlParams = `/${params.id}`
            delete params.id
          }
        }
        // Add the rest of the params as a query string
        if (!isEmpty(params)) {
          urlParams = `?${serializer(params)}`
        }
      } else if (typeof params === 'string' || typeof params === 'number') {
        // String or Number - eg. /recipes/23
        urlParams = `/${params}`
      } else {
        // Something else? Just log an error
        debug(
          "You provided params, but it wasn't an object!",
          inputUrl + endpoint + urlParams
        )
      }
    }

    let thisUrl = inputUrl + endpoint + urlParams

    // Add Body
    if (inputParams.body) {
      if (typeof inputParams.body === 'object') {
        if (method.toUpperCase() !== 'GET') {
          req.body = JSON.stringify(inputParams.body)
        } else {
          thisUrl += `?${queryString.stringify(inputParams.body, {
            arrayFormat: 'bracket'
          })}`
        }
      } else if (typeof inputParams.body === 'string') {
        thisUrl += inputParams.body
      } else {
        // Something else? Just log an error
        debug(
          "You provided params, but it wasn't an object or string!",
          inputUrl + endpoint + urlParams
        )
      }
    }

    // If responseType exist
    if (inputParams.responseType) {
      responseType = inputParams.responseType
    }

    // If another auth exist
    if (inputParams.auth) {
      req.headers.Authorization = inputParams.auth
    }

    // If contentType exist
    if (inputParams.contentType) {
      req.headers['Content-Type'] = inputParams.contentType
    }

    // If body data is formData
    if (inputParams.formData) {
      if (method.toUpperCase() === 'POST') req.body = inputParams.formData
    }

    debug(
      `REQUEST ${thisUrl}`,
      inputUrl + endpoint,
      inputParams.body,
      req.method,
      req.headers
    )

    if (inputParams.mock) {
      if (mockFunc) {
        const mockRes = mocker(mockFunc, inputParams.mock)
        debug(`MOCK RESPONSE ${thisUrl}`, inputUrl + endpoint, mockRes)
        clearTimeout(apiTimedOut)
        return resolve(mockRes)
      }

      debug(
        'There is not any mock function in this API constant',
        inputUrl + endpoint + urlParams
      )
    }

    const hasAbortId = Boolean(inputParams.ABORT_ID)

    if (hasAbortId) {
      abortController = {
        abortId: inputParams.ABORT_ID,
        value: new AbortController()
      }
    }

    // Make the request
    return (
      fetch(
        thisUrl,
        hasAbortId ? { ...req, signal: abortController.value.signal } : req
      )
        .then(async rawResponse => {
          // API got back to us, clear the timeout
          clearTimeout(apiTimedOut)

          let jsonResponse = {}

          if (!inputParams.contentType) {
            // Reporting export
            if (rawResponse.status === 202 && responseType === 'blob') {
              // eslint-disable-next-line no-throw-literal
              throw { code: 202, message: "File doesn't exist yet." }
            }
            if (rawResponse.status === 204) return ''
            if (rawResponse.status === 304)
              // eslint-disable-next-line no-throw-literal
              throw { message: 'Not modified', code: 304 }

            if (!rawResponse || rawResponse.status.toString().slice(0, 2) !== '20') {
              if (rawResponse) {
                jsonResponse = await rawResponse[responseType]()

                throw jsonResponse.error
              }

              throw new Error({ message: LABELS.error.networkError })
            }

            try {
              jsonResponse = await rawResponse[responseType]()
            } catch (error) {
              let err = ''

              if (!rawResponse.url.includes(inputUrl)) {
                err = { message: LABELS.error.networkError }
              } else {
                err = { message: LABELS.error.default }
              }

              throw err
            }
          }

          return jsonResponse
        })
        .then(response => {
          debug(`RESPONSE ${thisUrl}`, inputUrl + endpoint, response)
          return resolve(response)
        })
        // when response status code is not 200
        .catch(async error => {
          // API got back to us, clear the timeout
          clearTimeout(apiTimedOut)
          debug(`RESPONSE #${requestNum}`, inputUrl + endpoint, {
            message: error && error.message,
            code: error && error.code
          })

          if (!error) {
            return reject(LABELS.error.default)
          }

          const isShowErrorMsg = !ignoreErrorCodes.includes(error.code)

          if (error.code === 401) {
            // handle unauthorized states
            const excludedAuthRoutes = Object.values(AUTH_ROUTES)
            const { pathname } = history.location

            store.dispatch(logout())
            storage.remove(AUTH_TOKEN)

            // If user is in any auth page, there's no need to redirect
            // him/her to signin page

            if (!excludedAuthRoutes.includes(pathname)) {
              history.push(ROUTES.SIGNIN)
            }

            if (isShowErrorMsg) SnackManager.error('Please login again')
            return reject(new Error('401 Unauthorized'))
          }

          if (error.code === 403 || error.code === 404 || error.code === 405) {
            if (isShowErrorMsg)
              SnackManager.error(error.message || LABELS.error.default)
            return reject(error.message)
          }

          if (error.code === 422) {
            if (typeof error.message === 'string') {
              if (isShowErrorMsg)
                SnackManager.error(error.message || LABELS.error.default)
            } else {
              Object.keys(error.message).forEach(msg => {
                error.message[msg].forEach(err => {
                  if (isShowErrorMsg) SnackManager.error(err || LABELS.error.default)
                })
              })
            }
            return reject(error.message)
          }

          if (error.code === 500) {
            if (isShowErrorMsg)
              SnackManager.error(
                error.message ||
                  'Something went wrong. Please try again or contact BisFlow support.'
              )
            return reject(error.message)
          }

          if (!error.message || !error.code) {
            if (isShowErrorMsg) SnackManager.error(LABELS.error.default)
            return reject(LABELS.error.default)
          }

          return reject(error)
        })
    )
  })
}

export const abortFetcher = abortId => {
  if (!abortController) return

  if (abortController.abortId === abortId) {
    debug('REQUEST ABORTION', abortId, null, null, null)

    abortController.value.abort()
    abortController = null
  }
}

export default fetcher
