import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="prefix_eye__a"
          /* eslint-disable-next-line max-len */
          d="M12 3c3.38 0 6.339 1.632 8.855 4.316a20.496 20.496 0 012.25 2.891c.385.596.649 1.065.79 1.346.14.281.14.613 0 .894-.141.281-.405.75-.79 1.346a20.432 20.432 0 01-2.25 2.89C18.339 19.369 15.38 21 12 21c-3.38 0-6.339-1.632-8.855-4.316a20.496 20.496 0 01-2.25-2.891 15.36 15.36 0 01-.79-1.346 1.004 1.004 0 010-.894c.141-.281.405-.75.79-1.346a20.432 20.432 0 012.25-2.89C5.661 4.631 8.62 3 12 3zm0 2C9.255 5 6.776 6.368 4.605 8.684A18.402 18.402 0 002.141 12a18.614 18.614 0 002.463 3.317C6.777 17.631 9.255 19 12 19s5.224-1.368 7.395-3.684A18.402 18.402 0 0021.859 12a18.614 18.614 0 00-2.463-3.317C17.223 6.369 14.745 5 12 5zm0 3c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2a2 2 0 10-.001 3.999A2 2 0 0012 10z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id="prefix_eye__b" fill="#fff">
          <use xlinkHref="#prefix_eye__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#prefix_eye__a" />
        <g fill="#6B75FF" mask="url(#prefix_eye__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
