import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="prefix_info_red__a"
          /* eslint-disable-next-line max-len */
          d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 10a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zm0-4a1 1 0 100 2 1 1 0 000-2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id="prefix_info_red__b" fill="#fff">
          <use xlinkHref="#prefix_info_red__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#prefix_info_red__a" />
        <g fill="#FF3D71" mask="url(#prefix_info_red__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
