import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

export default (reducer, actions, defaultValue) => {
  const Context = React.createContext()

  const Provider = ({ children, mockValue }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue)

    const boundActions = {}
    Object.keys(actions).forEach(key => {
      boundActions[key] = actions[key](dispatch, state)
    })

    let value = { state, ...boundActions }

    if (mockValue) {
      const { mockState, ...mockMethods } = mockValue
      const newState = { ...state, ...mockState }
      value = { ...value, state: newState, ...mockMethods }
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
  }

  Provider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
      .isRequired,
    mockValue: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }

  Provider.defaultProps = { mockValue: false }

  return { Context, Provider }
}
