import React from 'react'

import ForwardArrowRight from './Icons/ForwardArrowRight'
import Tag from './Icons/Tag'
import TagFilled from './Icons/TagFilled'
import Check2 from './Icons/Check2'
import Check2Disable from './Icons/Check2Disable'
import Reply from './Icons/Reply'
import ReplyDisable from './Icons/ReplyDisable'
import Close from './Icons/Close'
import InfoRed from './Icons/InfoRed'
import ExternalLink from './Icons/ExternalLink'
import Print from './Icons/Print'
import Eye from './Icons/Eye'
import EyeFilled from './Icons/EyeFilled'
import Info from './Icons/Info'
import CreditCard1 from './Icons/CreditCard1'
import CreditCard2 from './Icons/CreditCard2'
import ChatIcon from './Icons/ChatIcon'
import LogoutIcon from './Icons/Logout'
import Send from './Icons/Send'
import Pencil from './Icons/Pencil'
import History from './Icons/History'
import Trash from './Icons/Trash'
import Bell from './Icons/Bell'
import Logo from './Icons/Logo'
import Filter from './Icons/Filter'
import Bell2 from './Icons/Bell2'
import PoweredByStripe from './Icons/PoweredByStripe'

// Credit-card related
import { Visa, MasterCard, JCB, AmericanExpress, Discover } from './Icons/CreditCards'

const getCreditCardIcon = name => {
  if (!name) return <CreditCard1 />

  const creditCardTypes = {
    visa: Visa,
    mastercard: MasterCard,
    'american-express': AmericanExpress,
    discover: Discover,
    jcb: JCB
  }

  const CreditCardIcon = creditCardTypes[name] || CreditCard1

  return <CreditCardIcon />
}

export default {
  ForwardArrowRight,
  Tag,
  TagFilled,
  Check2,
  Check2Disable,
  Reply,
  ReplyDisable,
  Close,
  InfoRed,
  ExternalLink,
  Print,
  Eye,
  EyeFilled,
  Info,
  CreditCard1,
  CreditCard2,
  ChatIcon,
  LogoutIcon,
  Send,
  Pencil,
  History,
  Trash,
  Bell,
  Logo,
  getCreditCardIcon,
  Filter,
  Bell2,
  PoweredByStripe
}

export {
  ForwardArrowRight,
  Tag,
  TagFilled,
  Check2,
  Check2Disable,
  Reply,
  ReplyDisable,
  Close,
  InfoRed,
  ExternalLink,
  Print,
  Eye,
  EyeFilled,
  Info,
  CreditCard1,
  CreditCard2,
  ChatIcon,
  LogoutIcon,
  Send,
  Pencil,
  History,
  Trash,
  Bell,
  Logo,
  getCreditCardIcon,
  Filter,
  Bell2,
  PoweredByStripe
}
