import { SET_INVOICES_LIST } from '../actions/invoicing'

const initialState = {
  list: null
}

function invoicingReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_INVOICES_LIST:
      return { ...state, list: action.payload }

    default:
      return state
  }
}

export default invoicingReducer
