import React from 'react'
import PropTypes from 'prop-types'
import NoSsr from '@material-ui/core/NoSsr'
import {
  MuiThemeProvider,
  StylesProvider,
  createGenerateClassName,
  createTheme
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Colors from './modules/colors.module.scss'
import Sizes from './modules/sizes.module.scss'

const generateClassName = createGenerateClassName({
  productionPrefix: 'to'
})

const theme = createTheme({
  palette: {
    primary: {
      light: Colors.primaryLightColor,
      main: Colors.primaryColor,
      dark: Colors.primaryDarkColor
    },
    secondary: {
      light: Colors.secondaryLightColor,
      main: Colors.secondaryColor
    },
    error: {
      main: Colors.errorColor
    },
    ...Colors
  },
  spacing: factor => `${0.25 * factor}rem`,
  typography: {
    fontFamily: '"Open Sans", sans-serif'
  },
  overrides: {
    MuiInput: {
      root: {
        '&.Mui-error': {
          color: Colors.errorColor
        },
        '&.Mui-disabled': {
          color: Colors.errorColor
        }
      }
    },
    MuiButton: {
      root: {
        height: Sizes.buttonHeightSize,
        boxSizing: 'border-box',
        '&$disabled': {
          // opacity: '0.3'
        }
      },
      label: {
        textTransform: 'capitalize',
        fontSize: Sizes.buttonFontSize,
        fontWeight: Sizes.buttonFontWeight
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        },
        '&:active': {
          boxShadow: 'none'
        }
      },
      containedPrimary: {
        '&.Mui-disabled': {
          color: Colors.white,
          opacity: '1',
          backgroundColor: Colors.disabled
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: Colors.labelColor,
        fontSize: Sizes.labelFontSize,
        fontWeight: Sizes.labelFontWeight,
        top: '-6px !important'
      }
    },
    MuiCheckbox: {
      root: {
        color: Colors.primaryColor
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(187, 194, 213, 0.6)'
      }
    },
    MuiFormHelperText: {
      root: {
        color: Colors.errorColor,
        marginTop: 4,
        fontWeight: 600
      }
    },
    MuiChip: {
      colorSecondary: {
        border: `1px solid ${Colors.secondaryColor}`,
        backgroundColor: Colors.white
      },
      outlinedSecondary: {
        color: Colors.semiBlack
      },
      clickable: {
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiSkeleton: {
      root: {
        backgroundColor: 'rgba(107, 117, 255, 0.3)'
      }
    }
  }
})

function ThemeProvider({ children }) {
  return (
    <NoSsr>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StylesProvider generateClassName={generateClassName}>
          {children}
        </StylesProvider>
      </MuiThemeProvider>
    </NoSsr>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ThemeProvider
