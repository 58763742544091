// Types
export const DELETE_MEMBER_LIST = 'DELETE_MEMBER_LIST'
export const ADD_MEMBER_LIST = 'ADD_MEMBER_LIST'
export const LOADING_MEMBER_LIST = 'LOADING_MEMBER_LIST'
export const TOGGLE_EDIT_CLIENT = 'TOGGLE_EDIT_CLIENT'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const ADD_CLIENT = 'ADD_CLIENT'
export const LOADING_CLIENT = 'LOADING_CLIENT'
export const EDITMODE_CLIENT = 'EDITMODE_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const ADD_PROJECT = 'ADD_PROJECT'
export const LOADING_PROJECT = 'LOADING_PROJECT'
export const EDITMODE_PROJECT = 'EDITMODE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_NAME = 'UPDATE_NAME'
export const UPDATE_CAPACITY = 'UPDATE_CAPACITY'
export const UPDATE_DAY = 'UPDATE_DAY'
export const UPDATE_HANDLEINPUT = 'UPDATE_HANDLEINPUT'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SUBMIT_MEMBER = 'SUBMIT_MEMBER'
export const SUBMIT_CLIENT = 'SUBMIT_CLIENT'
export const SUBMIT_PROJECT = 'SUBMIT_PROJECT'
export const SET_GENERATED_DATA = 'SET_GENERATED_DATA'
export const AFTER_SUBMIT = 'AFTER_SUBMIT'

// Creators

export const generatedData = payload => ({ type: SET_GENERATED_DATA, payload })

export const isAfterSubmit = payload => ({ type: AFTER_SUBMIT, payload })

// members
export const deleteMember = id => ({ type: DELETE_MEMBER_LIST, payload: id })

export const loadingMember = id => ({ type: LOADING_MEMBER_LIST, payload: id })

export const submitMember = payload => ({ type: SUBMIT_MEMBER, payload })

export const addMember = (name, id) => ({
  type: ADD_MEMBER_LIST,
  payload: { name, id, loading: false }
})

// clients

export const deleteClient = id => ({ type: DELETE_CLIENT, payload: id })

export const loadingClient = id => ({ type: LOADING_CLIENT, payload: id })

export const editModeClient = id => ({ type: EDITMODE_CLIENT, payload: id })

export const submitClient = payload => ({ type: SUBMIT_CLIENT, payload })

export const toggleEditId = payload => ({ type: TOGGLE_EDIT_CLIENT, payload })

export const addClient = (name, id) => ({
  type: ADD_CLIENT,
  payload: { name, id, loading: false, editMode: false }
})
export const updateClient = (name, id) => ({
  type: UPDATE_CLIENT,
  payload: { name, id }
})

// projects

export const deleteProject = id => ({ type: DELETE_PROJECT, payload: id })

export const loadingProject = id => ({ type: LOADING_PROJECT, payload: id })

export const editModeProject = id => ({ type: EDITMODE_PROJECT, payload: id })

export const submitProject = payload => ({ type: SUBMIT_PROJECT, payload })

export const addProject = (name, id) => ({
  type: ADD_PROJECT,
  payload: { name, id, loading: false, editMode: false }
})
export const updateProject = (name, id) => ({
  type: UPDATE_PROJECT,
  payload: { name, id }
})

// costumerInfo

export const updateName = name => ({ type: UPDATE_NAME, payload: name })

export const updateCapacity = number => ({ type: UPDATE_CAPACITY, payload: number })

export const updateDay = number => ({ type: UPDATE_DAY, payload: number })
// handle Inputs
export const handleInput = bool => ({ type: UPDATE_HANDLEINPUT, payload: bool })
// clear state
export const clearOnboardingState = payload => ({
  type: CLEAR_STATE,
  payload
})
