import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="24"
      viewBox="0 0 38 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#D7DAFF"
          /* eslint-disable-next-line max-len */
          d="M2.799 0C1.256 0 0 1.271 0 2.826V21.18C0 22.734 1.256 24 2.799 24h32.397C36.74 24 38 22.729 38 21.179V2.826C38 1.271 36.739 0 35.196 0H2.8z"
        />
        <path fill="#FFF" d="M0 5h38v4H0z" />
        <path fill="#FFF" stroke="#BBC0FB" d="M18 11h18v8H18z" />
        <text
          fill="#D7DAFF"
          fontFamily="OpenSans-SemiBold, Open Sans"
          fontSize="6"
          fontWeight="500"
          letterSpacing="-0.072"
          transform="translate(18 11)"
        >
          <tspan x="2" y="6">
            # # #
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default Icon
