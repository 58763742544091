import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="pencil__a"
          /* eslint-disable-next-line */
          d="M13.939 5.353l4.707 4.707L8.354 20.354a.5.5 0 01-.256.136l-5 1a.5.5 0 01-.588-.588l1-5a.5.5 0 01.136-.256L13.94 5.353zm6.415-1.707a3.33 3.33 0 010 4.708l-.294.292-4.707-4.707.293-.293a3.33 3.33 0 014.708 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id="pencil__b" fill="#fff">
          <use xlinkHref="#pencil__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#pencil__a" />
        <g fill="#6B75FF" mask="url(#pencil__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
