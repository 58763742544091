import { getDateISO } from 'libs/calendar'

import createDataContext from './createDataContext'

const getTimesheetStatusNumbers = status => {
  switch (status) {
    case 'all':
      return [2, 3, 5]
    case 'pending-for-approval':
      return [2]
    case 'approved':
      return [3]
    case 'invoiced':
      return [5]
    default:
      return [2, 3, 5]
  }
}

const initialState = {
  selectedClients: null,
  selectedProjects: null,
  selectedTags: null,
  selectedMembers: null,
  fromDate: null,
  toDate: null,
  isBillableHours: false,
  isNoneBillableHours: false,
  isTimeOffHours: false,
  timesheetStatus: 'all', // 2- pending-for-approval, 3- approved, 5- invoiced
  currentStatisticsOptions: {
    isBillableHours: false,
    isNoneBillableHours: false,
    isTimeOffHours: false,
    timesheetStatus: 'all'
  },
  current_page: 1,
  per_page: 50,
  total: 0,
  group_by: 'project',
  statistics: null,
  filteredData: null
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set_filter_data':
      return { ...state, [action.payload.key]: action.payload.value }

    case 'set_filter_from_to':
      return { ...state, fromDate: action.payload.from, toDate: action.payload.to }

    case 'set_current_statistics_options':
      return {
        ...state,
        currentStatisticsOptions: {
          ...state.currentStatisticsOptions,
          ...action.payload
        }
      }

    case 'set_filtered_data':
      return {
        ...state,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        group_by: action.payload.group_by,
        statistics: action.payload.statistics,
        filteredData: action.payload.filteredData,
        total: action.payload.total
      }

    default:
      return state
  }
}

const setCurrentStatisticsOptions = dispatch => payload => {
  dispatch({ type: 'set_current_statistics_options', payload })
}

const setFilterData = dispatch => ({ key, value }) => {
  dispatch({ type: 'set_filter_data', payload: { key, value } })
}

const setFilterFromAndTo = dispatch => ({ from, to }) => {
  dispatch({ type: 'set_filter_from_to', payload: { from, to } })
}

const setFilteredData = dispatch => data => {
  const { current_page, per_page, group_by, statistics, filteredData, total } = data

  dispatch({
    type: 'set_filtered_data',
    payload: { current_page, per_page, group_by, statistics, filteredData, total }
  })
}

const getFilterApiBodyData = () => (state, myPage = 1, byGroup = false) => {
  const { group_by } = state
  const stateGroupBy = group_by.charAt(0).toUpperCase() + group_by.slice(1)

  return {
    billable: state.isBillableHours ? '1' : '0',
    non_billable: state.isNoneBillableHours ? '1' : '0',
    time_off_hours: state.isTimeOffHours ? '1' : '0',
    client_ids: state.selectedClients ? state.selectedClients : [],
    project_ids: state.selectedProjects ? state.selectedProjects : [],
    tag_ids: state.selectedTags ? state.selectedTags : [],
    member_ids: state.selectedMembers ? state.selectedMembers : [],
    status: getTimesheetStatusNumbers(state.timesheetStatus),
    from_date: getDateISO(new Date(state.fromDate)),
    to_date: getDateISO(new Date(state.toDate)),
    page: myPage,
    per_page: state.per_page,
    group_by: byGroup || stateGroupBy
  }
}

const getExportRequestBody = () => (state, type) => {
  const body = getFilterApiBodyData()(state)

  body.export_type = type.toUpperCase()

  return body
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    setFilterData,
    setFilterFromAndTo,
    setFilteredData,
    setCurrentStatisticsOptions,
    getFilterApiBodyData,
    getExportRequestBody
  },
  initialState
)
