import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="send__a"
          // eslint-disable-next-line
          d="M4.894 2.447l15.528 7.764a2 2 0 010 3.578L4.894 21.553A2 2 0 012 19.763v-4.201a2 2 0 011.515-1.94L10 12l-6.485-1.621A2 2 0 012 8.439V4.235a2 2 0 012.894-1.789z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id="send__b" fill="#fff">
          <use xlinkHref="#send__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#send__a" />
        <g fill="#6B75FF" mask="url(#send__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
