import { SET_TOKEN, SET_USER_INFO, LOG_OUT } from '../actions/user'

const initialState = {
  token: null,
  info: {}
}

function userReducer(state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }

    case SET_USER_INFO:
      return {
        ...state,
        info: action.payload
      }

    case LOG_OUT:
      return {
        ...state,
        token: null,
        info: {}
      }

    default:
      return state
  }
}

export default userReducer
