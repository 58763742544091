const ModalManager = {
  modal: null,

  register(modal) {
    this.modal = modal
  },

  unregister() {
    this.modal = null
  },

  show(newState) {
    if (this.modal === null) {
      return
    }

    this.modal.show(newState)
  },

  hide(force = true) {
    if (this.modal === null) {
      return
    }

    this.modal.hide(force)
  }
}

export default ModalManager
