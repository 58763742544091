/*
  The idea is to provide a generic, and platform-independent storage API.
  In case of code-sharing, the storage API remains the same.
  Platform-specific codes such as 'localStorage' could be replaced by
  platform's storage API, e.g 'AsyncStorage' in react native.
 */

const storage = {
  save(key, value) {
    localStorage.setItem(key, value)
  },
  load(key) {
    return localStorage.getItem(key) || null
  },
  remove(key) {
    localStorage.removeItem(key)
  }
}

export default storage
