import { getInitialDateByType, getNumberOfWeek } from 'libs/calendar'
import {
  SET_PAGE,
  SET_LOADING,
  SET_LAZY_LOADING,
  SET_MEMBERS,
  SET_API_PARAMS,
  SET_IS_ADD_MODAL_OPEN,
  SET_ACTIVE_ROW,
  SET_MODAL_SUBMITTED_TYPE
} from '../actions/planning'

const getWeekNumber = dates => {
  const fromDate = dates[0]
  const splittedFrom = fromDate.split('-')
  const yearAndMonth = `${splittedFrom[0]}-${splittedFrom[1]}`
  const middleDay = Number(splittedFrom[2])
  const date = new Date(`${yearAndMonth}-${middleDay}`)
  const weekNumber = getNumberOfWeek(date)
  return weekNumber
}

const initialApiParams = {
  step_mode: 'weekly',
  year: new Date().getFullYear().toString(),
  step_of_year: getWeekNumber(getInitialDateByType('weekly')).toString(),
  include_weekend: '1',
  sort_type: 'asc',
  sort_by: 'Alphabetical'
}

const initialState = {
  page: 1,
  loading: true,
  lazyLoading: false,
  members: [],
  apiParams: { ...initialApiParams },
  isAddModalOpen: false,
  activeRow: null,
  modalSubmittedType: ''
}

function planningReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_PAGE:
      return { ...state, page: action.payload }

    case SET_LOADING:
      return { ...state, loading: action.payload }

    case SET_LAZY_LOADING:
      return { ...state, lazyLoading: action.payload }

    case SET_MEMBERS:
      return { ...state, members: action.payload }

    case SET_API_PARAMS:
      return { ...state, apiParams: action.payload }

    case SET_IS_ADD_MODAL_OPEN:
      return { ...state, isAddModalOpen: action.payload }

    case SET_ACTIVE_ROW:
      return { ...state, activeRow: action.payload }

    case SET_MODAL_SUBMITTED_TYPE:
      return { ...state, modalSubmittedType: action.payload }

    default:
      return state
  }
}

export default planningReducer
