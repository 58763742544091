/* eslint-disable max-len */
import { getArrayIntersection } from './utils'

/**
 * Check if user is permitted to perform a certain action
 *
 * @param {String[]} [toBeCheckedPermissions] - allowed permissions required to perform a certain action
 * @param {Object} permissionList - allowed permissions required to perform a certain action
 * @param {Object} [options] - extra options regarding allowed permissions
 * @param {Number|String} [options.projectId] - project id | "ALL"
 * @param {Number|String} [options.teamId] - team id | "ALL"
 * @param {Boolean} [options.strict] - if `true`, all toBeCheckedPermissions elements must be found in permissionList. Works only when projectId or teamId's value !== "ALL"
 */

const checkPermission = (
  toBeCheckedPermissions = [],
  permissionList = {
    regular: [],
    project: [],
    team: []
  },
  options = {
    projectId: null,
    teamId: null,
    strict: false
  }
) => {
  const { projectId, teamId, strict } = options
  let isPermitted = false

  if (!toBeCheckedPermissions.length) return true

  try {
    if (projectId === 'ALL') {
      const permissions = permissionList.project.reduce(
        (acc, current) => [...acc, current.permissions],
        []
      )

      isPermitted = permissions.some(item => {
        const includedPermissions = getArrayIntersection(
          toBeCheckedPermissions,
          item.map(({ name }) => name)
        )
        return Boolean(includedPermissions.length)
      })
    }

    if (projectId !== null && Number.isInteger(projectId)) {
      const project = permissionList.project.find(({ id }) => id === projectId)
      if (project) {
        const includedPermissions = getArrayIntersection(
          toBeCheckedPermissions,
          project.permissions.map(({ name }) => name)
        )
        if (strict) {
          isPermitted = includedPermissions.length === toBeCheckedPermissions.length
        } else if (includedPermissions.length) isPermitted = true
      }
    }

    if (isPermitted) return true

    if (teamId === 'ALL') {
      const permissions = permissionList.team.reduce(
        (acc, current) => [...acc, current.permissions],
        []
      )

      isPermitted = permissions.some(item => {
        const includedPermissions = getArrayIntersection(
          toBeCheckedPermissions,
          item.map(({ name }) => name)
        )
        return Boolean(includedPermissions.length)
      })
    }

    if (teamId !== null && Number.isInteger(teamId)) {
      const team = permissionList.team.find(({ id }) => id === teamId)
      if (team) {
        const includedPermissions = getArrayIntersection(
          toBeCheckedPermissions,
          team.permissions.map(({ name }) => name)
        )
        if (strict) {
          isPermitted = includedPermissions.length === toBeCheckedPermissions.length
        } else if (includedPermissions.length) isPermitted = true
      }
    }

    if (isPermitted) return true

    const includedPermissions = getArrayIntersection(
      toBeCheckedPermissions,
      permissionList.regular.map(({ name }) => name)
    )

    if (strict) {
      isPermitted = includedPermissions.length === toBeCheckedPermissions.length
    } else if (includedPermissions.length) isPermitted = true

    return isPermitted
  } catch (err) {
    return true
  }
}

export default checkPermission
