import PropTypes from 'prop-types'

export default {
  loading: PropTypes.bool,
  isShowPlan: PropTypes.bool,
  openPopupType: PropTypes.string,
  overview: PropTypes.shape({
    canceled_at: PropTypes.string,
    billing_email: PropTypes.string,
    default_payment_method: PropTypes.string, // TODO: check really is string type?
    ends_at: PropTypes.string,
    plan: {
      currency: PropTypes.string,
      description: PropTypes.string,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          max_value: PropTypes.number,
          name: PropTypes.string,
          resettable_interval: PropTypes.string,
          title: PropTypes.string
        })
      ),
      id: PropTypes.number,
      invoice_interval: PropTypes.string,
      is_active: PropTypes.bool,
      name: PropTypes.string,
      plan_type: PropTypes.string,
      price: PropTypes.number,
      unit_label: PropTypes.string
    },
    quantity: PropTypes.number,
    used_quantity: PropTypes.number,
    renew_charge: PropTypes.number,
    renew_date: PropTypes.string,
    subscribed: PropTypes.bool,
    subscription_type: PropTypes.string,
    trial_ends_at: PropTypes.string
  }),
  plansList: PropTypes.array,
  adminMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      name: PropTypes.string
    })
  )
}
