// Types
export const SET_LOADING = 'SET_LOADING'
export const SET_OVERVIEW = 'SET_OVERVIEW'
export const SET_PLANS_LIST = 'SET_PLANS_LIST'
export const IS_SHOW_PLANS_POPUP = 'IS_SHOW_PLANS_POPUP'
export const OPEN_POPUP_TYPE = 'OPEN_POPUP_TYPE'
export const SET_ADMIN_MEMBERS = 'SET_ADMIN_MEMBERS'

// Creators
export const setLoading = payload => ({ type: SET_LOADING, payload })
export const setOverview = payload => ({ type: SET_OVERVIEW, payload })
export const setPlansList = payload => ({ type: SET_PLANS_LIST, payload })
export const setIsShowPlansPopup = payload => ({ type: IS_SHOW_PLANS_POPUP, payload })
export const setOpenPopupType = payload => ({ type: OPEN_POPUP_TYPE, payload })
export const setAdminMembers = payload => ({ type: SET_ADMIN_MEMBERS, payload })
