/* eslint-disable no-unused-vars */
import { dateFormatted } from 'libs/utils'
import moment from 'moment'
import {
  SET_INVOICE_LOADING,
  SET_CLIENT_ID,
  SET_PROJECTS_LIST,
  SET_PROJECTS,
  SET_VIEW_TYPE,
  SET_OTHER_ITEMS,
  SET_INVOICE_FEE,
  SET_CLIENT_VAT_NUMBER,
  SET_PAYMENT_METHODS,
  SET_COMPANY_INFO,
  SET_ATTACHMENT_IDS,
  SET_INVOICE_DATE,
  SET_DUE_DATE,
  SET_INTEREST_RATE,
  SET_REMINDER_FEE,
  SET_REMINDERS,
  SET_REMINDERS_CHECKED,
  SET_DISCOUNT_TYPE,
  SET_DISCOUNT,
  SET_DISCOUNT_CHECKED,
  SET_CHECK_RESULT,
  SET_ALL,
  SET_CLIENT_NAME,
  SET_UNINVOICED_DATA,
  SET_HAS_ERROR,
  SET_ATTACHED_DETAILED_REPORT,
  SET_INITIAL
} from '../actions/invoicingNew'

const initialReminder = {
  fire_days: 5,
  subject: 'Reminder for invoice #invoice ID from Company name',
  message:
    // eslint-disable-next-line
    'Dear Customer,\n\nThis is a friendly reminder to let you know that Invoice #InvoiceID is 5 days past due. If you have already sent the payment, please disregard this message. If not, we would appreciate your prompt attention to this matter.\n\nThank you for your business.\n\nCheers,\nCompany name\n\n---------------------------------------------\nInvoice Summary\n---------------------------------------------\nInvoice ID: 6\nIssue Date: 2019-07-30\nClient: [SAMPLE] Client B\nP.O. Number:\nAmount: kr25 625,00\nDue: 2019-07-30 (upon receipt)\n\nThe detailed invoice is attached as a PDF.\n---------------------------------------------'
}

export const initialState = {
  invoiceLoading: true,
  clientName: '',
  client_id: null,
  projectsList: [],
  projectDone: false,
  projects: [],
  export_by_persons: 0,
  export_by_time_entries: 0,
  other_items: [],
  invoice_fee: { value: '50', checked: false },
  client_vat_number: { value: '123456789', checked: false },
  payment_methods: [],
  payment_method_ids: [],
  tax_type: 1,
  company_info: {
    name: '',
    org_number: '',
    vat_number: '',
    invoice_ref_person: '',
    telephone: '',
    email: '',
    website: '',
    address: '',
    addresses: [],
    default_address_id: null
  },
  attachment_ids: [],
  invoice_date: dateFormatted(new Date()),
  due_date: dateFormatted(
    moment()
      .add(30, 'days')
      .toDate()
  ),
  interest_rate: '7.5',
  reminder_fee: '60',
  reminders: null,
  reminders_checked: false,
  discount: { type: 'Percent', rate: 0 },
  discountType: 'total', // or separate
  discount_checked: false,
  check_result: {
    invoice_fee: 0,
    discount_total: 0,
    tax_total: 0,
    net: 0,
    total: 0,
    rounding: 0
  },
  confirm: 1,
  hasError: false,
  hasUnInvoicedData: false
}

function invoicingNewReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_INVOICE_LOADING:
      return { ...state, invoiceLoading: action.payload }

    case SET_CLIENT_ID:
      return { ...state, client_id: action.payload }

    case SET_CLIENT_NAME:
      return { ...state, clientName: action.payload }

    case SET_HAS_ERROR:
      return { ...state, hasError: action.payload }

    case SET_UNINVOICED_DATA:
      return { ...state, hasUnInvoicedData: action.payload }

    case SET_PROJECTS_LIST:
      return {
        ...state,
        projectsList: action.payload.projectsList,
        projectDone: action.payload.projectDone
      }

    case SET_PROJECTS:
      return { ...state, projects: action.payload }

    case SET_VIEW_TYPE:
      return { ...state, view_type: action.payload }

    case SET_OTHER_ITEMS:
      return { ...state, other_items: action.payload }

    case SET_INVOICE_FEE:
      return { ...state, invoice_fee: action.payload }

    case SET_CLIENT_VAT_NUMBER:
      return { ...state, client_vat_number: action.payload }

    case SET_PAYMENT_METHODS:
      return { ...state, payment_methods: action.payload }

    case SET_COMPANY_INFO:
      return { ...state, company_info: action.payload }

    case SET_ATTACHMENT_IDS:
      return { ...state, attachment_ids: action.payload }

    case SET_INVOICE_DATE:
      return { ...state, invoice_date: action.payload }

    case SET_DUE_DATE:
      return { ...state, due_date: action.payload }

    case SET_INTEREST_RATE:
      return { ...state, interest_rate: action.payload }

    case SET_REMINDER_FEE:
      return { ...state, reminder_fee: action.payload }

    case SET_REMINDERS:
      return { ...state, reminders: action.payload }

    case SET_REMINDERS_CHECKED:
      return { ...state, reminders_checked: action.payload }

    case SET_DISCOUNT_TYPE:
      return { ...state, discountType: action.payload }

    case SET_DISCOUNT:
      return { ...state, discount: action.payload }

    case SET_DISCOUNT_CHECKED:
      return { ...state, discount_checked: action.payload }

    case SET_CHECK_RESULT:
      return { ...state, check_result: action.payload }

    case SET_ATTACHED_DETAILED_REPORT:
      return { ...state, export_by_time_entries: action.payload }

    case SET_ALL:
      return { ...state, ...action.payload }

    case SET_INITIAL:
      return {
        ...initialState,
        invoiceLoading: false,
        payment_methods: state.payment_methods,
        company_info: state.company_info
      }

    default:
      return state
  }
}

export default invoicingNewReducer
