import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="trash__a"
          // eslint-disable-next-line
          d="M12 1a3 3 0 013 3v1h4a1 1 0 010 2h-1v13a3 3 0 01-3 3H5a3 3 0 01-3-3V7H1a1 1 0 110-2h4V4a3 3 0 013-3h4zM9 7H7v14h2V7zm4 0h-2v14h2V7zm-1-4H8a1 1 0 00-1 1v1h6V4a1 1 0 00-1-1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(2)">
        <path fillRule="nonzero" d="M4 0h24v24H4z" />
        <mask id="trash__b" fill="#fff">
          <use xlinkHref="#trash__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#trash__a" />
        <g fill="#6B75FF" mask="url(#trash__b)">
          <path d="M-2 0h24v24H-2z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
