import {
  SET_MEMBERS,
  SET_MEMBERS_LOADING,
  SET_CLIENTS,
  SET_CLIENTS_LOADING,
  SET_PROJECTS,
  SET_PROJECTS_LOADING,
  SET_PROJECT_ROLES,
  SET_PROJECT_ROLES_LOADING,
  SET_TAGS,
  SET_IS_OWNERSHIP_TRANSFERED,
  SET_TAGS_LOADING
} from '../actions/settings'

const initialState = {
  // members
  members: null,
  membersLoading: false,

  // clients
  clients: null,
  clientsLoading: false,

  // projects
  projects: null,
  projectsLoading: false,

  // project roles
  projectRoles: null,
  projectRolesLoading: false,

  // tags
  tags: null,
  tagsLoading: false,

  // transferOwnership
  isTransfered: false
}

function settingsReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_MEMBERS:
      return { ...state, members: action.payload }

    case SET_MEMBERS_LOADING:
      return { ...state, membersLoading: action.payload }

    case SET_CLIENTS:
      return { ...state, clients: action.payload }

    case SET_CLIENTS_LOADING:
      return { ...state, clientsLoading: action.payload }

    case SET_PROJECTS:
      return { ...state, projects: action.payload }

    case SET_PROJECTS_LOADING:
      return { ...state, projectsLoading: action.payload }

    case SET_PROJECT_ROLES:
      return { ...state, projectRoles: action.payload }

    case SET_PROJECT_ROLES_LOADING:
      return { ...state, projectRolesLoading: action.payload }

    case SET_TAGS:
      return { ...state, tags: action.payload }

    case SET_TAGS_LOADING:
      return { ...state, tagsLoading: action.payload }

    case SET_IS_OWNERSHIP_TRANSFERED:
      return { ...state, isTransfered: action.payload }

    default:
      return state
  }
}

export default settingsReducer
