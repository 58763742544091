import { useContext } from 'react'

import { Context as ReportingContext } from 'contexts/ReportingContext'
import { Context as TimeApprovalContext } from 'contexts/TimeApprovalContext'
import { Context as TimesheetContext } from 'contexts/TimesheetContext'
import { Context as AuthContext } from 'contexts/AuthContext'
import { Context as GlobalContext } from 'contexts/GlobalContext'

const contexts = {
  ReportingContext,
  TimeApprovalContext,
  TimesheetContext,
  AuthContext,
  GlobalContext
}

/**
 * @param {string} contextName - Your context name
 * @returns {object}
 * @description To avoid importing contexts in every file
 */

const useStore = contextName => {
  const context = useContext(contexts[contextName])

  return context
}

export default useStore
