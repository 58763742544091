/**
 * @author <amirmohammad.moradi@gmail.com>
 * @description Apis urls constants needed for api config
 */

import { PROTOCOL, HOSTNAME, PREFIX, VERSION } from './global'

const URL = `${PROTOCOL}://${HOSTNAME}/${PREFIX}/${VERSION}`

const APP_ENDPOINTS = [
  { key: 'search', url: '/search/smart', method: 'GET' },
  { key: 'getTimeOffTags', url: '/time_off_tags', method: 'GET' }
]

const GENERAL_ENDPOINTS = [
  { key: 'getTimezones', url: '/generals/timezones', method: 'GET' },
  { key: 'getCountries', url: '/generals/countries', method: 'GET' }
]
const ONBOARDING_ENDPOINTS = [
  {
    key: 'setWorkspaceDetails',
    url: '/companies/workspace-details',
    method: 'PATCH'
  },
  { key: 'getSubscriptionPlans', url: '/subscription_plans', method: 'GET' },
  {
    key: 'requestEnterprise',
    url: '/subscriptions/enterprise-request',
    method: 'POST'
  },
  {
    key: 'getCurrentPlan',
    url: '/subscriptions/overview',
    method: 'GET'
  },
  {
    key: 'getChosenPlan',
    url: '/companies/detail',
    method: 'GET'
  },
  {
    key: 'addExtraMembers',
    url: '/tenants/add-extra-feature',
    method: 'PATCH'
  }
]

const PAYMENT_ENDPOINTS = [
  {
    key: 'checkoutSubscription',
    url: '/subscriptions/checkout',
    method: 'POST'
  },
  {
    key: 'getSubscriptionInvoice',
    url: '/subscriptions/{id}/invoice',
    method: 'GET'
  },
  {
    key: 'addNewPaymentMethod',
    url: '/customer_payment_methods',
    method: 'POST'
  },
  {
    key: 'getClientSecret',
    url: '/customer_payment_methods/client_secret',
    method: 'GET'
  },
  {
    key: 'getDefaultPaymentMethod',
    url: '/customer_payment_methods/default',
    method: 'GET'
  }
]

const MEMBER_ENDPOINTS = [
  { key: 'getMembers', url: '/members', method: 'GET' },
  { key: 'addMember', url: '/members', method: 'POST' },
  { key: 'getMember', url: '/members/{id}', method: 'GET' },
  { key: 'deleteMembers', url: '/members/{id}', method: 'DELETE' },
  { key: 'updateMember', url: '/members/{id}', method: 'PUT' },
  { key: 'getProfile', url: '/members/me', method: 'GET' },
  { key: 'getMemberProjects', url: '/members/{id}/projects', method: 'GET' }
]

const PROFILE_ENDPOINTS = [
  { key: 'getMyProfile', url: '/profiles/me', method: 'GET' },
  { key: 'updateMyProfile', url: '/profiles/me', method: 'PUT' },
  { key: 'changeMyPassword', url: '/profiles/me/changepass', method: 'PATCH' },
  {
    key: 'getRegularPermissions',
    url: '/profiles/regular_permissions',
    method: 'GET'
  },
  {
    key: 'getProjectPermissions',
    url: '/profiles/project_permissions',
    method: 'GET'
  },
  {
    key: 'getTeamPermissions',
    url: '/profiles/team_permissions',
    method: 'GET'
  },
  {
    key: 'addGdprRequest',
    url: '/members/{id}/getGdprRequest',
    method: 'GET'
  },
  {
    key: 'getGdprStatus',
    url: '/members/{id}/getGdprStatus',
    method: 'GET'
  },
  {
    key: 'downloadGdpr',
    url: '/members/{id}/downloadGdpr',
    method: 'GET'
  }
]

const PROJECT_ENDPOINT = [
  { key: 'getProjects', url: '/projects', method: 'GET' },
  { key: 'getProjectById', url: '/projects/{id}', method: 'GET' },
  { key: 'addProject', url: '/projects', method: 'POST' },
  { key: 'updateProject', url: '/projects/{id}', method: 'PUT' },
  { key: 'deleteProject', url: '/projects/{id}', method: 'DELETE' },
  { key: 'getProjectClients', url: '/projects/{id}/client', method: 'GET' },
  { key: 'getProjectTags', url: '/projects/{id}/tags', method: 'GET' }
]

const TAGS_ENDPOINT = [
  { key: 'getTags', url: '/tags/categories', method: 'GET' },
  { key: 'addTags', url: '/tags', method: 'POST' },
  { key: 'deleteTags', url: '/tags/{id}', method: 'DELETE' },
  { key: 'updateTags', url: '/tags/{id}', method: 'PUT' }
]

const CLIENTS_ENDPOINTS = [
  { key: 'getClients', url: '/clients/getClientList', method: 'GET' },
  { key: 'getClientsList', url: '/clients', method: 'GET' },
  { key: 'getClientById', url: '/clients/{id}', method: 'GET' },
  { key: 'addClient', url: '/clients', method: 'POST' },
  { key: 'updateClient', url: '/clients/{id}', method: 'PUT' },
  { key: 'deleteClient', url: '/clients/{id}', method: 'DELETE' },
  { key: 'getClientsProjects', url: '/clients/{id}/projects', method: 'GET' },
  { key: 'getClientsHoursChanges', url: '/clients/{id}/hours-changes', method: 'GET' }
]

const INVOICING_ENDPOINTS = [
  { key: 'getAllInvoice', url: '/invoices', method: 'GET' },
  {
    key: 'getAllSubscriptionInvoices',
    url: '/subscriptions/{id}/invoices',
    method: 'GET'
  },
  { key: 'getInvoice', url: '/invoices/{id}', method: 'GET' },
  { key: 'editInvoice', url: '/invoices/{id}', method: 'Put' },
  { key: 'saveDraft', url: '/invoices/saveDraft', method: 'POST' },
  { key: 'editDraft', url: '/invoices/{id}/editDraft', method: 'Put' },
  { key: 'getInvoiceHistory', url: '/invoices/{id}/history', method: 'GET' },
  { key: 'calcInvoiceValues', url: '/invoices/calculate-values', method: 'POST' },
  { key: 'addInvoice', url: '/invoices', method: 'POST' },
  { key: 'sendInvoice', url: '/invoices/{id}/send', method: 'POST' },
  { key: 'cancelInvoice', url: '/invoices/{id}/cancel', method: 'PUT' },
  { key: 'deleteInvoice', url: '/invoices/{id}', method: 'DELETE' },
  { key: 'markAsInvoice', url: '/invoices/{id}/status', method: 'Put' },
  { key: 'getInvoiceReminders', url: '/invoices/{id}/reminders', method: 'GET' },
  { key: 'addInvoiceReminder', url: '/reminders', method: 'POST' },
  { key: 'editInvoiceReminder', url: '/reminders/{reminderId}', method: 'PUT' },
  { key: 'deleteInvoiceReminder', url: '/reminders/{reminderId}', method: 'DELETE' }
]

const MEDIA_ENDPOINTS = [
  { key: 'upload', url: '/media/upload', method: 'POST' },
  { key: 'deleteMedia', url: '/media/{uuid}', method: 'DELETE' },
  { key: 'downloadInvoice', url: '/media/download/{id}', method: 'GET' }
]

const FORTNOX = [
  { key: 'statusFortnox', url: '/members/{id}/statusFortnox', method: 'GET' },
  { key: 'getFortnoxInvoice', url: '/members/{id}/getFortnoxInvoice', method: 'GET' },
  {
    key: 'initialConnection',
    url: '/members/{id}/initialConnection',
    method: 'POST'
  },
  {
    key: 'getAuthorizeToken',
    url: '/members/{id}/getAuthorizeToken',
    method: 'POST'
  },
  {
    key: 'deleteFortnoxConnection',
    url: '/members/{id}/deleteFortnox',
    method: 'DELETE'
  },
  {
    key: 'getFortnoxCustomer',
    url: '/members/{id}/getFortnoxCustomer',
    method: 'GET'
  },
  {
    key: 'updateClientFortnoxMap',
    url: '/clients/{id}/updateClientFortnoxMap',
    method: 'PUT'
  },
  {
    key: 'mapOneToOneFortnox',
    url: '/members/{id}/mapOneToOneFortnox',
    method: 'POST'
  }
]

const COMPANY_ENDPOINT = [
  { key: 'getCompanyDetails', url: '/companies/detail', method: 'GET' },
  { key: 'updateCompany', url: '/companies', method: 'PUT' },
  { key: 'getInvoiceSignature', url: '/companies/signature', method: 'GET' },
  { key: 'updateInvoiceSignature', url: '/companies/signature', method: 'PUT' },
  { key: 'updateInvoiceSetting', url: '/companies/settings', method: 'PUT' }
]

const PROJECT_ROLE_ENDPOINTS = [
  { key: 'getAllProjectRoles', url: '/project_roles', method: 'GET' },
  { key: 'getProjectRoleById', url: '/project_roles/{id}', method: 'GET' },
  { key: 'addProjectRole', url: '/project_roles', method: 'POST' },
  { key: 'deleteProjectRole', url: '/project_roles/{id}', method: 'DELETE' },
  { key: 'updateProjectRole', url: '/project_roles/{id}', method: 'PUT' }
]

const RESOURCE_PLAN_ENDPOINTS = [
  {
    key: 'getPlansList',
    url: '/resource_plans',
    method: 'GET'
  },
  { key: 'getPlanTimeOffs', url: '/resource_plans/{id}/time_offs', method: 'GET' },
  { key: 'getPlanProjects', url: '/resource_plans/{id}/projects', method: 'GET' },
  {
    key: 'getWeeklyPlanProjects',
    url: '/resource_plans/{id}/weekly_planning',
    method: 'GET'
  },
  {
    key: 'getPlanProjectsList',
    url: '/resource_plans/{id}/projects_list',
    method: 'GET'
  },
  { key: 'getPlanningReports', url: '/resource_plans/report', method: 'GET' },
  {
    key: 'generatePlanningExport',
    url: '/resource_plans/generate_export',
    method: 'POST'
  },
  {
    key: 'downloadPlanningExport',
    url: '/resource_plans/download_export',
    method: 'GET'
  }
]

const TIME_PLAN_ENDPOINTS = [
  {
    key: 'storeTimePlan',
    url: '/time_plans/store',
    method: 'POST'
  },
  { key: 'totalStatistic', url: '/time_plans/total_statistic', method: 'GET' },
  { key: 'getTimePlan', url: '/time_plans/{id}', method: 'GET' },
  { key: 'deleteTimePlan', url: '/time_plans/{id}', method: 'DELETE' },
  { key: 'undoDeleteTimePlan', url: '/time_plans/undo/{id}', method: 'PATCH' },
  { key: 'deleteTimePlanGroup', url: '/time_plan_groups/{id}', method: 'DELETE' },
  {
    key: 'undoDeleteTimePlanGroup',
    url: '/time_plan_groups/undo/{id}',
    method: 'PATCH'
  }
]

const TIME_OFF_ENDPOINTS = [
  { key: 'storeTimeOff', url: '/time_offs/store', method: 'POST' },
  { key: 'getTimeOff', url: '/time_offs/{id}', method: 'GET' },
  { key: 'deleteTimeOff', url: '/time_offs/{id}', method: 'DELETE' },
  { key: 'undoDeleteTimeOff', url: '/time_offs/undo/{id}', method: 'PATCH' },
  { key: 'deleteTimeOffGroup', url: '/time_off_groups/{id}', method: 'DELETE' },
  {
    key: 'undoDeleteTimeOffGroup',
    url: '/time_off_groups/undo/{id}',
    method: 'PATCH'
  }
]

const MEMENTO_ENDPOINTS = [
  { key: 'undo', url: '/memento/undo/{actionHashCode}', method: 'PATCH' }
]

const AVATAR_ENDPOINTS = [
  { key: 'avatar', url: '/memento/undo/{actionHashCode}', method: 'GET' }
]

const TENANT_ENDPOINTS = [
  { key: 'tenantsDetail', url: '/tenants/detail', method: 'GET' },
  { key: 'tenantsTransferOwner', url: '/tenants/transfer_owner', method: 'PATCH' }
]

const TIME_SHEET_ENDPOINTS = [
  {
    key: 'getTimesheetProjectsList',
    url: '/time_sheets/projects_list',
    method: 'GET'
  },
  { key: 'getTimesheetsByDate', url: '/time_sheets/get_by_date', method: 'GET' },
  { key: 'getTimesheetList', url: '/time_sheets', method: 'GET' },
  { key: 'getTimesheetById', url: '/time_sheets/{id}', method: 'GET' },
  { key: 'postTimeSheets', url: '/time_sheets', method: 'POST' },
  { key: 'putTimeSheets', url: '/time_sheets/{id}', method: 'PUT' },
  { key: 'timeSheetEditRequest', url: '/time_sheets/edit_request', method: 'PATCH' },
  { key: 'timeSheetApprove', url: '/time_sheets/approve', method: 'PATCH' },
  { key: 'timeSheetDecline', url: '/time_sheets/send_back_pending', method: 'PATCH' },
  { key: 'timeSheetEditAccept', url: '/time_sheets/edit_accept', method: 'PATCH' },
  { key: 'timeSheetEditReject', url: '/time_sheets/edit_reject', method: 'PATCH' },
  { key: 'getTimesheetReports', url: '/time_sheets/report', method: 'GET' },
  {
    key: 'generateTimesheetExport',
    url: '/time_sheets/generate_export',
    method: 'POST'
  },
  {
    key: 'checkExportGeneration',
    url: '/time_sheets/check_export_generation',
    method: 'GET'
  },
  {
    key: 'downloadTimesheetExport',
    url: '/time_sheets/download_export',
    method: 'GET'
  }
]

const DASHBOARD_ENDPOINT = [
  { key: 'getInsights', url: '/insights/projects', method: 'GET' },
  // { key: 'getPlans', url: '/resource_plans/times_by_date', method: 'GET' },
  { key: 'getPlans', url: '/resource_plans/times_for_dashboard', method: 'GET' },
  { key: 'addToTimesheet', url: '/resource_plans/add_to_timesheet', method: 'POST' }
]

const SUBSCRIPTIONS_ENDPOINT = [
  { key: 'getSubscriptionsOverview', url: '/subscriptions/overview', method: 'GET' },
  { key: 'subscriptionPlansList', url: '/subscription_plans', method: 'GET' },
  { key: 'cancelSubscriptions', url: '/subscriptions/cancel', method: 'PATCH' },
  { key: 'regretSubscriptions', url: '/subscriptions/regret', method: 'PATCH' },
  {
    key: 'getcalculateProration',
    url: '/subscriptions/calculate_proration',
    method: 'GET'
  },
  {
    key: 'changeSubscription',
    url: '/subscriptions/change',
    method: 'PUT'
  },
  {
    key: 'deleteWorkspace',
    url: '/members/{id}/deleteTenant',
    method: 'GET'
  },
  {
    key: 'subscriptionChangeBillingDetails',
    url: '/subscriptions/change_billing_details',
    method: 'PATCH'
  }
]

const CUSTOMER_PAYMENT_METHODS_ENDPOINT = [
  {
    key: 'getDefaultCustomerPaymentMethods',
    url: 'customer_payment_methods/default',
    method: 'GET'
  }
]

export default {
  url: URL,
  endpoints: [
    ...APP_ENDPOINTS,
    ...GENERAL_ENDPOINTS,
    ...PROFILE_ENDPOINTS,
    ...MEMBER_ENDPOINTS,
    ...PROJECT_ENDPOINT,
    ...TAGS_ENDPOINT,
    ...COMPANY_ENDPOINT,
    ...TIME_PLAN_ENDPOINTS,
    ...PROJECT_ROLE_ENDPOINTS,
    ...RESOURCE_PLAN_ENDPOINTS,
    ...INVOICING_ENDPOINTS,
    ...TIME_OFF_ENDPOINTS,
    ...MEMENTO_ENDPOINTS,
    ...AVATAR_ENDPOINTS,
    ...CLIENTS_ENDPOINTS,
    ...MEDIA_ENDPOINTS,
    ...TENANT_ENDPOINTS,
    ...TIME_SHEET_ENDPOINTS,
    ...DASHBOARD_ENDPOINT,
    ...ONBOARDING_ENDPOINTS,
    ...PAYMENT_ENDPOINTS,
    ...FORTNOX,
    ...SUBSCRIPTIONS_ENDPOINT,
    ...CUSTOMER_PAYMENT_METHODS_ENDPOINT
  ]
}
