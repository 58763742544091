import React from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'

const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
  setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef
const setUseSnackbarRef = useSnackbarRefProp => {
  useSnackbarRef = useSnackbarRefProp
}

export const Snackbar = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

export default {
  success(msg, action = null, ...rest) {
    this.toast(msg, 'success', action, ...rest)
  },
  warning(msg, action = null, ...rest) {
    this.toast(msg, 'warning', action, ...rest)
  },
  info(msg, action = null, ...rest) {
    this.toast(msg, 'info', action, ...rest)
  },
  error(msg, action = null, ...rest) {
    this.toast(msg, 'error', action, ...rest)
  },
  toast(msg, variant = 'default', action = null, ...rest) {
    const options = {
      variant,
      preventDuplicate: true,
      ...rest
    }

    if (action) {
      options.action = <Button onClick={action.callback}>{action.title}</Button>
    }

    if (!useSnackbarRef) return null

    return useSnackbarRef.enqueueSnackbar(msg, options)
  },
  closeAll() {
    useSnackbarRef.closeSnackbar()
  },
  close(key) {
    useSnackbarRef.closeSnackbar(key)
  }
}
