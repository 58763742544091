// Types
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO'
export const SET_LOADING = 'SET_LOADING'

// Creators

export const setLoading = payload => ({
  type: SET_LOADING,
  payload
})

export const setCompanyInfo = payload => ({ type: SET_COMPANY_INFO, payload })
