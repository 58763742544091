// Types
export const SET_MEMBERS = 'SET_MEMBERS'
export const SET_MEMBERS_LOADING = 'SET_MEMBERS_LOADING'
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_CLIENTS_LOADING = 'SET_CLIENTS_LOADING'
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_PROJECTS_LOADING = 'SET_PROJECTS_LOADING'
export const SET_PROJECT_ROLES = 'SET_PROJECT_ROLES'
export const SET_PROJECT_ROLES_LOADING = 'SET_PROJECT_ROLES_LOADING'
export const SET_TAGS = 'SET_TAGS'
export const SET_TAGS_LOADING = 'SET_TAGS_LOADING'
export const SET_IS_OWNERSHIP_TRANSFERED = 'SET_IS_OWNERSHIP_TRANSFERED'

// Creators
export const setMembers = payload => ({ type: SET_MEMBERS, payload })

export const setClients = payload => ({ type: SET_CLIENTS, payload })

export const setProjects = payload => ({ type: SET_PROJECTS, payload })

export const setProjectRoles = payload => ({ type: SET_PROJECT_ROLES, payload })

export const setTags = payload => ({ type: SET_TAGS, payload })

// Loadings
export const setMembersLoading = payload => ({ type: SET_MEMBERS_LOADING, payload })

export const setProjectsLoading = payload => ({ type: SET_PROJECTS_LOADING, payload })

export const setProjectRolesLoading = payload => ({ type: SET_PROJECT_ROLES_LOADING, payload }) // eslint-disable-line

export const setTagsLoading = payload => ({ type: SET_TAGS_LOADING, payload })

export const setClientsLoading = payload => ({ type: SET_CLIENTS_LOADING, payload })

// transfer Ownership
export const setIsOwnershipTransfered = payload => ({
  type: SET_IS_OWNERSHIP_TRANSFERED,
  payload
})
