const {
  REACT_APP_PROTOCOL,
  REACT_APP_HOSTNAME,
  REACT_APP_PREFIX,
  REACT_APP_VERSION
} = process.env

const PROTOCOL = REACT_APP_PROTOCOL
const HOSTNAME = REACT_APP_HOSTNAME
const PREFIX = REACT_APP_PREFIX
const VERSION = REACT_APP_VERSION

const LABELS = {
  error: {
    networkError: 'Network Error',
    timeOut: 'Timeout Error',
    default: 'Server is not available. Try later!'
  }
}
const SUBSCRIPTION_PRICE = { monthly: 100, yearly: 80 }

export { PROTOCOL, HOSTNAME, PREFIX, VERSION, LABELS, SUBSCRIPTION_PRICE }
