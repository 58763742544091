// Types
export const SET_INSIGHTS = 'SET_INSIGHTS'
export const SET_PLANS = 'SET_PLANS'
export const SET_LOADING = 'SET_LOADING'

// Creators

export const setLoading = payload => ({
  type: SET_LOADING,
  payload
})

export const setInsights = payload => ({ type: SET_INSIGHTS, payload })
export const setPlans = payload => ({ type: SET_PLANS, payload })
