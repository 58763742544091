/**
 * @author <amirmohammad.moradi@gmail.com>
 * @description Apis urls constants needed for api config
 */

import { PROTOCOL, HOSTNAME, PREFIX } from './global'

const URL = `${PROTOCOL}://${HOSTNAME}/${PREFIX}`

const ENDPOINTS = [
  { key: 'login', url: '/auth/login', method: 'POST' },
  { key: 'getUserInfo', url: '/auth/user', method: 'GET' },
  { key: 'signup', url: '/auth/signup', method: 'POST' },
  { key: 'confirmSignup', url: '/auth/signup_activate', method: 'POST' },
  { key: 'resendActivationCode', url: '/auth/send_code_again', method: 'POST' },
  {
    key: 'checkUserVerification',
    url: '/auth/verification/{invite_token}',
    method: 'GET'
  },
  {
    key: 'sendPasswordResetLink',
    url: '/password/create',
    method: 'POST'
  },
  {
    key: 'checkResetPasswordToken',
    url: '/password/find/{token}',
    method: 'GET'
  },
  {
    key: 'resetPassword',
    url: '/password/reset',
    method: 'POST'
  }
]

export default {
  url: URL,
  endpoints: [...ENDPOINTS]
}
