import React, { memo, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { __RouterContext } from 'react-router'
import cx from 'clsx'

import { removeLastArrayElement, includesDigits } from 'libs/utils'
import useGa from 'hooks/useGa'
// import { ROUTES_HAVE_HEADER_BACK_BUTTON } from 'routes/routes'

import useTranslate from 'hooks/useTranslate'
import s from './HeaderBackButton.module.scss'

function HeaderBackButton({ sticky }) {
  const { history } = useContext(__RouterContext) // , location
  const [category, setCategory] = useState('')
  const ga = useGa(category)
  const t = useTranslate('general')

  useEffect(() => {
    const temp = history.location.pathname
      .split('/')
      .filter(Boolean)
      .filter(route => !Number(route))
    if (temp.length > 1) {
      setCategory(temp[1])
    } else {
      setCategory(temp[0])
    }
  })

  // const ga =useGa('')
  // const [isOpen, setIsOpen] = useState(true)
  //
  // useEffect(() => {
  //   const isRouteWithHeader = ROUTES_HAVE_HEADER_BACK_BUTTON.includes(
  //     location.pathname
  //   )
  //
  //   if (isRouteWithHeader && !isOpen) setIsOpen(true)
  //
  //   if (!isRouteWithHeader && isOpen) setIsOpen(false)
  // }, [location.pathname]) // eslint-disable-line

  const handleClick = () => {
    ga('back')
    try {
      const { pathname, state } = history.location

      if (state && state.redirectUrl) {
        history.push(state.redirectUrl)
        return
      }

      if (includesDigits(pathname)) {
        history.goBack()
        return
      }

      const currentRouteStack = pathname
        .split('/')
        .filter(Boolean)
        .filter(route => !Number(route))

      if (currentRouteStack.length >= 2) {
        const newRouteStack = removeLastArrayElement(currentRouteStack)
        const newRoutePathname = newRouteStack.join('/')

        history.push(`/${newRoutePathname}`)
      } else {
        history.goBack()
      }
    } catch (err) {
      history.goBack()
    }
  }

  return (
    <div
      className={cx(s.container, sticky ? s.sticky : '')} // , { [s.open]: isOpen }
      id="header-back-button"
    >
      <button type="button" onClick={handleClick} className={s.backButton}>
        <div className="icon-back" />
        <span>{t('back')}</span>
      </button>
    </div>
  )
}

HeaderBackButton.propTypes = {
  sticky: PropTypes.bool
}

HeaderBackButton.defaultProps = {
  sticky: true
}

export default memo(HeaderBackButton)
