import {
  SET_FILTER_DATA,
  SET_FILTER_FROM_AND_TO,
  SET_CURRENT_STATISTICS_OPTIONS,
  SET_FILTERED_DATA
} from '../actions/reports'

export const initialState = {
  selectedClients: null,
  selectedProjects: null,
  selectedTags: null,
  selectedMembers: null,
  fromDate: null,
  toDate: null,
  isBillableHours: false,
  isNoneBillableHours: false,
  isTimeOffHours: false,
  timesheetStatus: 'all', // 2- pending-for-approval, 3- approved, 5- invoiced
  currentStatisticsOptions: {
    isBillableHours: false,
    isNoneBillableHours: false,
    isTimeOffHours: false,
    timesheetStatus: 'all'
  },
  current_page: 1,
  per_page: 50,
  total: 0,
  group_by: 'project',
  statistics: null,
  filteredData: null
}

function reportsReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_FILTER_DATA:
      return {
        ...state,
        // action.payload.key:
        // selectedClients || selectedProjects || selectedTags || selectedMembers
        [action.payload.key]: action.payload.value
      }

    case SET_FILTER_FROM_AND_TO:
      return {
        ...state,
        fromDate: action.payload.from,
        toDate: action.payload.to
      }

    case SET_CURRENT_STATISTICS_OPTIONS:
      return {
        ...state,
        currentStatisticsOptions: {
          ...state.currentStatisticsOptions,
          ...action.payload
        }
      }

    case SET_FILTERED_DATA:
      return {
        ...state,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        group_by: action.payload.group_by,
        statistics: action.payload.statistics,
        filteredData: action.payload.filteredData,
        total: action.payload.total
      }

    default:
      return state
  }
}

export default reportsReducer
