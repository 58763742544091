import React, { PureComponent } from 'react'
import Button from '@material-ui/core/Button'
import cx from 'clsx'

import ModalManager from 'libs/modal'
import Modal from '../Modal'

import s from './ModalConfirm.module.scss'

const INITIAL_STATE = {
  isModalVisible: false,
  title: '',
  content: null,
  acceptText: '',
  declineText: '',
  onAccept: null,
  onDecline: null,
  closable: true,
  persistOnAccept: false,
  acceptDisabled: false,
  classes: {}
}

class ModalConfirm extends PureComponent {
  static showCallback() {
    ModalManager.isShown = true
  }

  static hideCallback() {
    ModalManager.isShown = false
  }

  constructor(props) {
    super(props)

    this.state = INITIAL_STATE

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.onAccept = this.onAccept.bind(this)
    this.onDecline = this.onDecline.bind(this)
  }

  componentDidMount() {
    ModalManager.register(this)
  }

  componentWillUnmount() {
    ModalManager.unregister()
  }

  onAccept() {
    const { onAccept, persistOnAccept } = this.state
    if (!persistOnAccept) this.hide(true)
    onAccept()
  }

  onDecline() {
    const { onDecline } = this.state
    this.hide(true)
    onDecline()
  }

  show(newState) {
    this.setState({
      ...INITIAL_STATE,
      ...newState,
      isModalVisible: true
    })
  }

  hide() {
    this.setState({ isModalVisible: false })
  }

  render() {
    const {
      isModalVisible,
      title,
      acceptText,
      declineText,
      content,
      acceptDisabled,
      classes
    } = this.state

    return (
      <Modal
        open={isModalVisible}
        onClose={this.hide}
        classes={{
          backdrop: s.backdrop,
          paper: s.paper,
          closeIcon: s.modalClose
        }}
      >
        <div className={cx(s.container, classes.contentWrapper)}>
          <div className={s.header}>
            <p className={s.title}>{title}</p>
          </div>

          <div className={s.content}>{content}</div>

          <div className={cx(s.footer, classes.footer)}>
            <div className={s.footerButton}>
              <Button
                variant="contained"
                color="primary"
                data-testid="confirm-modal"
                classes={{
                  root: s.okButton,
                  label: s.okButtonLabel,
                  disabled: s.disabled
                }}
                type="button"
                onClick={this.onAccept}
                disabled={acceptDisabled}
              >
                {acceptText || 'Ok'}
              </Button>
            </div>
            <div className={s.footerButton}>
              <Button
                classes={{ root: s.cancelButton, label: s.cancelButtonLabel }}
                type="button"
                onClick={this.onDecline}
              >
                {declineText || 'Cancel'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default ModalConfirm
