import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="prefix_close__a"
          /* eslint-disable-next-line max-len */
          d="M5.707 4.293L12 10.585l6.293-6.292a1 1 0 011.414 1.414L13.415 12l6.292 6.293a1 1 0 01-1.414 1.414L12 13.415l-6.293 6.292a1 1 0 01-1.414-1.414L10.585 12 4.293 5.707a1 1 0 011.414-1.414z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 24 0)">
        <path fillRule="nonzero" d="M0 0h24v24H0z" />
        <mask id="prefix_close__b" fill="#fff">
          <use xlinkHref="#prefix_close__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#prefix_close__a" />
        <g fill="#6B75FF" mask="url(#prefix_close__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
