// Types
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const SET_FILTER_FROM_AND_TO = 'SET_FILTER_FROM_AND_TO'
export const SET_CURRENT_STATISTICS_OPTIONS = 'SET_CURRENT_STATISTICS_OPTIONS'
export const SET_FILTERED_DATA = 'SET_FILTERED_DATA'

// Creators
export const setCurrentStatisticsOptions = payload => ({
  type: SET_CURRENT_STATISTICS_OPTIONS,
  payload
})

export const setFilterData = payload => ({ type: SET_FILTER_DATA, payload })

export const setFilterFromAndTo = payload => ({
  type: SET_FILTER_FROM_AND_TO,
  payload
})

export const setFilteredData = payload => ({ type: SET_FILTERED_DATA, payload })
