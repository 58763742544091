import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="prefix_external_link__a"
          /* eslint-disable-next-line max-len */
          d="M9 3a1 1 0 010 2H3a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1v-6a1 1 0 012 0v6a3 3 0 01-3 3H3a3 3 0 01-3-3V6a3 3 0 013-3h6zm10-3h.02c.023 0 .046.002.07.004L19 0c.05 0 .1.004.149.011l.052.01a.762.762 0 01.065.015l.046.014.06.021.051.023.061.03.037.022a1.2 1.2 0 01.074.05l.022.017c.063.05.12.107.17.17l-.08-.09a.927.927 0 01.147.186l.021.037c.011.02.022.04.031.06l.023.053.021.06.014.045.016.065.009.053.007.06.003.056L20 1v6a1 1 0 01-2 0V3.414l-9.293 9.293a1 1 0 01-1.414-1.414L16.584 2H13a1 1 0 010-2h6z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(2 2)">
        <mask id="prefix_external_link__b" fill="#fff">
          <use xlinkHref="#prefix_external_link__a" />
        </mask>
        <use fill="#6B75FF" fillRule="nonzero" xlinkHref="#prefix_external_link__a" />
        <g fill="#FFF" mask="url(#prefix_external_link__b)">
          <path d="M-2-2h24v24H-2z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
