// Types
export const SET_YEAR_AND_STEP = 'SET_YEAR_AND_STEP'
export const SET_TAB_VALUE = 'SET_TAB_VALUE'
export const SET_SORT_BY = 'SET_SORT_BY'
export const SET_filter = 'SET_filter'
export const SET_MEMBER_DATA = 'SET_MEMBER_DATA'
export const SET_TIME_APPROVAL_LIST = 'SET_TIME_APPROVAL_LIST'
export const SET_TIME_APPROVAL_DETAILS = 'SET_TIME_APPROVAL_DETAILS'
export const SET_DIALOG_DATA = 'SET_DIALOG_DATA'
export const SET_DIALOG_LOADING = 'SET_DIALOG_LOADING'
export const SET_PATCH_RES = 'SET_PATCH_RES'

// Creators
export const setYearAndStep = payload => ({ type: SET_YEAR_AND_STEP, payload })

export const setTabValue = payload => ({ type: SET_TAB_VALUE, payload })

export const setSortBy = payload => ({ type: SET_SORT_BY, payload })

export const setFilter = payload => ({ type: SET_filter, payload })

export const setMemberData = payload => ({ type: SET_MEMBER_DATA, payload })

export const setTimeApprovalList = payload => ({
  type: SET_TIME_APPROVAL_LIST,
  payload
})

export const setTimeApprovalDetails = payload => ({
  type: SET_TIME_APPROVAL_DETAILS,
  payload
})

export const setDialogData = payload => ({ type: SET_DIALOG_DATA, payload })

export const setDialogLoading = payload => ({ type: SET_DIALOG_LOADING, payload })

export const setPatchRes = payload => ({ type: SET_PATCH_RES, payload })
