import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import LinearProgress from '@material-ui/core/LinearProgress'

import s from './ProgressBar.module.scss'

function ProgressBar({ show, className, ...rest }) {
  if (!show) return null

  return (
    <div className={cx(s.container, className)}>
      <LinearProgress {...rest} />
    </div>
  )
}

ProgressBar.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string
}

ProgressBar.defaultProps = {
  show: true,
  className: ''
}

export default ProgressBar
